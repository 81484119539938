import {Breadcrumb} from "antd";
import React, {ReactElement, useState} from "react";
import BreadCrumbItem from "./BreadCrumbItem";
import {BCItem} from "../typings";

interface Props {
  bcItems:BCItem[]
  popBreadCrumbItem: (v: BCItem) => void
}


const DynamicBreadCrumb: React.FC<Props> = ({
                                              bcItems,
                                              popBreadCrumbItem,
                                            }) => {

  // const [items,setItems] = useState(bcItems)
  const onItemHandle = (item: BCItem) => {
    if (bcItems.length == 1) {
      return
    }
    // remove item  from breadcrumb items
    popBreadCrumbItem(item)

  }
  return (
    <>
      <div>

        <Breadcrumb separator={'=>'}>
          {
            bcItems.map((item, index
            ) => {
              return <BreadCrumbItem key={item.itemId} item={item}
                                     onItemHandle={(item: BCItem) => onItemHandle(item)}/>
            })
          }

        </Breadcrumb>

      </div>

    </>
  )
}

export default DynamicBreadCrumb;


