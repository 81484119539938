import {EntityAttributeType} from "./EntityTypes";
import {RelationAttributeType} from "./RelationTypes";

// fixme state has changed! Luckily nothing is broken.
export type State = 'PUBLISH_DONE' | 'DRAFT' | 'PUBLISHING'
export enum ANNOTATION_TYPE {'RESOURCE_TYPE' = 'RESOURCE_TYPE'}
export enum RESOURCE_TYPE {'OBJECT_STORAGE' = 'OBJECT_STORAGE' }
export enum META_ENTITY_BIND_DATASOURCE {'POSTGRES' = 'postgres', 'K2BOXNG' = 'k2boxng',
'K2BOX' = 'k2box',
'CSV' = 'csv',
'LOCALFS' = 'localfs',
'MINIO' = 'minio',
'IOTDB' = 'iotdb',
'S3FS' = 's3fs'}

/**
 * should be used using `moment(xxx)`
 */
export type TimestampLike = string | number;

export interface BaseType {
      name?: string,
      desc?: string,
      display?: string,

      readonly created_at?: TimestampLike,
      readonly created_user?: string,
      readonly updated_at?: TimestampLike,
      readonly updated_user?: string
}

export type AttributeDataType = 'int' // fixme will be fetch from backend, so list is not accurate
    | 'string'
    | 'float'
    | 'bool'
    | 'date'
    | 'datetime'
    | 'JSON'
    | 'enum'
    | 'relation'

export type ObjectType = 'ENTITY_TYPE'
    | 'EXTERNAL_ENTITY_TYPE'
    | 'VALUE_TYPE'
    | 'RELATION_TYPE'


/**
 * 从后端返回的数据类型的定义
 */
export interface DataTypeType extends BaseType {
      namespace_name?: string,
      options: Array<{ // 是一个对象数组，每一个对应一个控件
            name: string,
            desc: string,
            defaultValue?: any,
            type: DataTypeInputType,
            [otherProps: string]: any
      }> | null
}

// 指定了要提供的数据类型（也同时决定了控件）
export type DataTypeInputType = 'number' | 'array' | 'select'


export interface DraftsType extends BaseType {
      data_source_id?: number,
      entity_types: ETDraftType[] | null,
      relation_types: RelationDraftType[] | null
}

export interface ETDraftType extends BaseType {
      data_source_id?: number,
      attributes: EntityAttributeType[] | null
}

export interface RelationDraftType extends BaseType {
      data_source_id?: number
      attributes: RelationAttributeType[] | null
}
