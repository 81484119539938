// @ts-nocheck
import env from 'day4-utils/es/env';
import request from '../../sdk/request';
import {DimentionRelationshipType} from '../types/DimentionRelationship';
import {EntityAttributeType, EntityType} from '../types/EntityTypes';
import {
  CreateEntityDataTypeTemplate, deleteEntityDataTypeRecordTemplate, DeleteFileObjectTemplate,
  EntityDataDataFrameTypes,
  EntityDataTypes,
  QueryEntityConnectionTemplate, QueryEntityConnectionWithOffsetTemplate,
  QueryEntityDataTemplate,
  QueryRelationshipDataConnectionTemplate, updateEntityDataTypeTemplate, updateRelationEntityDataTypeTemplate
} from "../types/EntityDataTypes";

const api = env.DAY4_APP_MODELER_META_API;
const graphql_api = env.DAY4_APP_MODELER_GRAPHQL;

const entityApi = {
  create(params: EntityType) {
    const {namespace} = params;
    return request<EntityType>({
      url: `${api}/namespaces/${namespace}/entity_types`,
      data: params,
      method: 'POST',
    });
  },

  remove(params: EntityType) {
    const {namespace, name: entity} = params;
    return request({
      url: `${api}/namespaces/${namespace}/entity_types/${entity}`,
      method: 'DELETE',
    });
  },

  update(params: EntityType) {
    const {old_name: entity, namespace, ...restParams} = params;
    return request<EntityType>({
      url: `${api}/namespaces/${namespace}/entity_types/${entity}`,
      data: restParams,
      method: 'PUT',
    });
  },

  detail(namespace: string, entity: string) {
    return request<EntityType>({
      url: `${api}/namespaces/${namespace}/entity_types/${entity}`,
    }).then(res => {
      // sorting is no longer needed!
      // if (res?.data.attributes) {
      //   res.data.attributes.sort(byAttributeType)
      // }
      return res;
    });
  },

  list(namespace: string, params?: any) {
    return request<EntityType[]>({
      url: `${api}/namespaces/${namespace}/entity_types`,
      params,
    }).then(res => {
      // if (res?.data) {
      //   res.data.forEach(d => {
      //     if (d.attributes) {
      //       d.attributes.sort(byAttributeType)
      //     }
      //   })
      // }
      return res;
    });
  },

  entityTemplate() {
    return request<EntityType[]>({
      url: `${api}/entity_definition_templates`,
    }).then(res => {
      return res;
    });
  },

  upsertAttributes(
    namespace: string,
    entity: string,
    attributes: EntityAttributeType[]
  ) {
    // fixme clarify response data
    return request<any>({
      url: `${api}/namespaces/${namespace}/entity_types/${entity}/attributes/_batch`,
      method: 'POST',
      data: attributes,
    });
  },

  convertAttributeToRelational(
    namespace: string,
    entity: string,
    attribute: string,
    paylod: DimentionRelationshipType
  ) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/entity_types/${entity}/attributes/${attribute}/relation`,
      method: 'PUT',
      data: paylod,
    });
  },

  fetchEntityData(entity: EntityType, columns:string[], filter:any) {
    let query = QueryEntityDataTemplate(entity.name, columns, filter)
    console.log("query entity data template :", query)
    return request<EntityDataTypes>({
        url: `${graphql_api}/${entity.namespace}/graphql`,
        method: 'POST',
        params: {'query': QueryEntityDataTemplate(entity.name, columns, filter)},
      }
    );
  },

  //查看实体数据
  fetchEntityDataFrameData(entity: EntityType) {
    const attrs = entity.attributes
    if (!attrs || attrs.length == 0) {
      return null
    }
    let columns = [];
    for (let attr of attrs) {
      if (attr.type !== "relation") {
        columns.push(attr.name)
      } else {
        // columns.push(attr.name)
      }
    }
    const query = `{${entity.name} { ${columns.join(" ")} } }`
    return request<EntityDataDataFrameTypes>({
        url: `${graphql_api}/namespaces/${entity.namespace}/data_frame`,
        method: 'GET',
        params: {'query': query},
      }
    );
  },



  fetchConnectionEntityData(entity: EntityType,
                            cursor: string,
                            pageSize: number,
                            isNextPage: boolean,
                            filter: string,
                            queryColumns:string[],
                            ) {

    const query = QueryEntityConnectionTemplate(isNextPage,
      entity.name ? entity.name : "",
      cursor, pageSize, queryColumns, filter)
    console.log("query:", query)
    return request<any>({
        url: `${graphql_api}/${entity.namespace}/graphql`,
        method: 'POST',
        params: {'query': query},
      }
    );
  },

  fetchConnectionEntityDataWithOffset(entity: EntityType,
                            current: number,
                            pageSize: number,
                            offset:number,
                            filter: string,
                            queryColumns:string[],
  ) {

    const query = QueryEntityConnectionWithOffsetTemplate(
      entity.name ? entity.name : "",
      current, pageSize, offset, queryColumns, filter)

    return request<any>({
        url: `${graphql_api}/${entity.namespace}/graphql`,
        method: 'POST',
        params: {'query': query},
      }
    );
  },

  fetchConnectionEntityRelationshipData(relationshipEntity: EntityType, filter:string) {
    if (relationshipEntity.name) {
      const query = QueryRelationshipDataConnectionTemplate(relationshipEntity.name, filter)
      return request<any>({
          url: `${graphql_api}/${relationshipEntity.namespace}/graphql`,
          method: 'POST',
          params: {'query': query},
        }
      );
    }
    },

  // 创建记录
  fetchCreateEntityDataType(entity: EntityType, createData:string, queryColumns:string[]) {
    const query = CreateEntityDataTypeTemplate(entity.name, createData, queryColumns)
    console.log("create entity data type :", query)
    return request<any>({
        url: `${graphql_api}/${entity.namespace}/graphql`,
        method: 'POST',
        params: {'query': query},
      }
    );
  },

  fetchUpdateEntityDataType(entity: EntityType, identity:string, data:string, queryColumns:string[]) {
    const query = updateEntityDataTypeTemplate(entity.name, identity, data, queryColumns)
    console.log("update entity data type :", query)
    return request<any>({
          url: `${graphql_api}/${entity.namespace}/graphql`,
          method: 'POST',
          params: {'query': query},
        }
    );
  },

  fetchUpdateRelationEntityDataType(entity: EntityType, updateExp:string, deleteExp: string[], createExp: string[]) {
    const query = updateRelationEntityDataTypeTemplate(entity.name, updateExp, deleteExp, createExp)
    console.log("update relation entity data type :", query)
    return request<any>({
          url: `${graphql_api}/${entity.namespace}/graphql`,
          method: 'POST',
          params: {'query': query},
        }
    );
  },


  fetchDeleteEntityDataTypeRecord(entity: EntityType, identity:string, queryColumns:string[]) {
    const query = deleteEntityDataTypeRecordTemplate(entity.name, identity, queryColumns)
    console.log("delete entity data type :", query)
    return request<any>({
          url: `${graphql_api}/${entity.namespace}/graphql`,
          method: 'POST',
          params: {'query': query},
        }
    );
  },


  importEntityDataFromCSV(namespace: string, entity:string | undefined,  file: any) {
    return request<any>({
      url: `${graphql_api}/namespaces/${namespace}/${entity}/data/_import_entity_csv`,
      method: 'POST',
      data: file,
    });
  },

  uploadFileObjects(namespace:string, entity:string, formData:any) {
    return request<any>({
      url: `${graphql_api}/namespaces/${namespace}/entity_type/${entity}/upload`,
      method: 'POST',
      data: formData,
    });
  },

  deleteFileObject(namespace:string, entity:string, file_object_name:string) {
    const query = DeleteFileObjectTemplate(entity,file_object_name)
    console.log('delete file object query template =>', query)
    return request<any>({
          url: `${graphql_api}/${namespace}/graphql`,
          method: 'POST',
          params: {'query': query },
        }
    );
  }

};

export default entityApi;

// -------------------------- 属性排序算法 ------------------------------

type Comparator<T> = (a: T, b: T) => number;

const byAttributeType = compose(
  primaryComparator,
  accessComparator,
  nameComparator
);

function compose<T>(...funcs: Comparator<T>[]): Comparator<T> {
  return (a, b) => {
    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];
      const result = func(a, b);
      if (result !== 0) {
        return result;
      } else {
        // no-op
      }
    }
    return 0;
  };
}

function primaryComparator(a: EntityAttributeType, b: EntityAttributeType) {
  const aa = a.is_primary ? 1 : 0;
  const bb = b.is_primary ? 1 : 0;
  return bb - aa;
}

function accessComparator(a: EntityAttributeType, b: EntityAttributeType) {
  const aa = a.type === 'relation' ? 1 : 0;
  const bb = b.type === 'relation' ? 1 : 0;
  return bb - aa;
}

function nameComparator(a: EntityAttributeType, b: EntityAttributeType) {
  const aa = a.name;
  const bb = b.name;
  if (aa < bb) {
    return -1;
  } else if (aa > bb) {
    return 1;
  } else {
    return 0;
  }
}
