import {UploadProps} from "antd";
import {EntityType} from "../../api/types/EntityTypes";
import {RESOURCE_TYPE} from "../../api/types/CommonTypes";


export interface IAction {
  type: ACTION_TYPE,
  payload: any
}

export enum ACTION_TYPE {
  SETTING_FORM_DATA = 'setting_form_data',
  SETTING_META_ENTITY = 'setting_meta_entity',
  SETTING_RESOURCE_TYPE = 'setting_resource_type',
  SETTING_UPLOAD_BUTTON_DISABLE = 'SETTING_upload_button_disable',
  SETTING_LOADING = 'setting_loading',
  UPLOAD_VALIDATE = 'upload_validate'
}


export interface IState {
  formData: any
  isLoading:boolean
  disabled:boolean
  uploadValidate:boolean
  uploadComponentProps:UploadProps<any>
  resource_type:any
}


