import ModalAddForeignKeyRelationship from './ModalAddForeignKeyRelationship';

ModalAddForeignKeyRelationship.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template',
    },
    {
      name: 'type1',
      type: 'string',
      help: '关系左面的实体类型（master）',
    },
    {
      name: 'type2',
      type: 'string',
      help: '关系右面的实体类型（child）',
    },
    {
      name: 'type2_is_external',
      type: 'boolean',
    },
    {
      name: 'attribute2',
      type: 'EntityAttributeType',
      help: '关系依据的外键属性',
    },
    {
      name: 'showButton',
      type: 'boolean',
    },
    {
      name: 'buttonLabel',
      type: 'string',
    },
    {
      name: 'disabled',
      type: 'boolean',
    },
  ],
  events: [
    {
      type: 'ok',
    },
  ],
  editorConfig: {
    defaultValue: {
      showButton: true,
      buttonLabel: '转换为访问属性',
      disabled: false,
      type2_is_external: false,
      type1: '',
      type2: '',
    },
  },
};

export { ModalAddForeignKeyRelationship };
