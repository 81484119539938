import {ACTION_TYPE, IAction, IState, EntityRecord, IFileObjectState, kUploadFile} from "./typings";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";
import {ChildValue, FormItem, RelationshipRecords} from "./useUpsertEntityData";
import {RcFile} from "antd/es/upload";

function recordReducer(state: IState,action:IAction):IState{
  const { type,payload } = action;
  switch(type){
    // case ACTION_TYPE.INIT_ENTITY:
    //   return{
    //     ...state,
    //     entity: payload as EntityType
    //   }
    case ACTION_TYPE.INIT_RELATIONSHIPS:
      return{
        ...state,
        relationships: payload as RelationType[]
      }
    case ACTION_TYPE.INIT_RELATION_ALL_RECORDS:
      return{
        ...state,
        relationAllRecords: payload as RelationshipRecords[]
      }
    case ACTION_TYPE.INIT_RELATIONSHIP_RECORDS:
      return{
        ...state,
        relationshipRecords: payload as RelationshipRecords[]
      }
    case ACTION_TYPE.INIT_FORM_ITEMS:
      return{
        ...state,
        formItems: payload as FormItem[]
      }
    case ACTION_TYPE.ADD_RECORD:
      return{
        ...state,
        record: payload as EntityRecord
      }
    case ACTION_TYPE.UPDATE_RECORD:
      return{
        ...state,
        record: payload as EntityRecord
      }

    case ACTION_TYPE.MODAL_VISIBLE:
      return{
        ...state,
        modalVisible: payload as boolean
      }

    default:
      return state;
  }
}
function uploadFileObjectReducer(state: IFileObjectState,action:IAction):IFileObjectState{
  const { type,payload } = action;
  switch(type){
    case ACTION_TYPE.ADD_FILE_OBJECT:
     const file =  payload as kUploadFile
      for (let item of state.formItems) {
        if (item.child.name === '上传') {
          let childValue = new ChildValue();
          childValue.value = file
          item.child.values.push(childValue)
          break
        }
      }
      return{
        ...state,
        formItems:state.formItems
      }
    case ACTION_TYPE.UPDATE_FILE_OBJECT:
      const uploadFile =  payload as kUploadFile
      for (let item of state.formItems) {
        if (item.child.name === '上传') {
          for (let cv of item.child.values) {
            if (cv.value.uid === uploadFile.uid) {
              cv.value = uploadFile
              break
            }
          }
          break
        }
      }
      return{
        ...state,
        formItems: state.formItems
      }
    case ACTION_TYPE.REMOVE_FILE_OBJECT:
      const rmFile =  payload as kUploadFile
      for (let item of state.formItems) {
        if (item.child.name === '上传') {
          //移除匹配的文件
          item.child.values = item.child.values.filter(file => file.value.uid !== rmFile.uid);
          break
        }
      }
      return{
        ...state,
        formItems: state.formItems
      }
    case ACTION_TYPE.REMOVE_ONLINE_FOR_SPINNING:
      return{
        ...state,
        spinning:  payload as boolean
      }
    case ACTION_TYPE.OBJECT_STORAGE:
      return{
        ...state,
        object_storage: payload as boolean
      }

    default:
      return state;
  }
}
export {
  recordReducer,
  uploadFileObjectReducer
}
