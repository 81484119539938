//@ts-nocheck
import CreateEntityData from "./CreateEntityData";

CreateEntityData.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template',
    },
    {
      name: 'entity',
      type: 'template',
    }
  ],
  styles: ['width', 'height'],
  editorConfig: {
    defaultValue: {
      disabled: false
    }
  }
}


export default CreateEntityData;
