import ModalAddDataSource from './ModalAddDS'


ModalAddDataSource.register = {
   attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'showButton',
      type: "boolean"
    }
  ],
  
  editorConfig: {
    defaultValue: {
      showButton: true
    }
  },
  
  styles: ['width', 'height'],
  events: [{
    type: 'ok'
  }]
}

export default ModalAddDataSource
