import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";
import {FormItem, RelationshipRecords} from "./useUpsertEntityData";
import {RcFile, UploadChangeParam} from "antd/lib/upload/interface";
import {UploadFile} from "antd/es/upload/interface";
import {UploadRequestOption} from "rc-upload/lib/interface";


export interface EntityRecord {

}

export type kUploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed' | 'waiting';
export interface kUploadFile extends UploadFile {
  onLineStatus?: kUploadFileStatus;
}

export interface IFileObjectState {
  formItems: FormItem[],
  fileList: Array<kUploadFile>,
  preview:{
    image:string,
    visible:boolean,
    title:string,
  }
  handleChange:(info:UploadChangeParam) => void
  handlePreview: (file:kUploadFile) => Promise<void>
  beforeUpload:(file:RcFile) => void
  customRequest:(options: UploadRequestOption, form_item:FormItem) => void
  submit: (form:any, fieldsValue:{}, callback:Function) => void
  removeFile:(file:kUploadFile) => void
  removeOnlineFile:(file:kUploadFile) => void
  spinning:boolean
  object_storage:boolean
}

export interface IState {
  record?: EntityRecord
  resource_type?: string
  relationships?: RelationType[]
  relationAllRecords?: RelationshipRecords[]
  relationshipRecords?: RelationshipRecords[]
  formItems?: FormItem[]
  modalVisible:boolean

}

export interface IAction {
  type: ACTION_TYPE,
  payload: any
}

export enum ACTION_TYPE {
  ADD_RECORD = 'add_record',
  UPDATE_RECORD = 'update_record',
  INIT_ENTITY = 'init_entity',
  INIT_RELATIONSHIPS = 'init_relationships',
  INIT_RELATIONSHIP_RECORDS = 'init_relationship_records',
  INIT_RELATION_ALL_RECORDS = 'init_relation_all_records',
  INIT_FORM_ITEMS = 'init_form_items',
  MODAL_VISIBLE = 'modal_visible',


  /// file object type
  OBJECT_STORAGE = 'object_storage',
  ADD_FILE_OBJECT = 'add_file_object',
  UPDATE_FILE_OBJECT = 'update_file_object',
  REMOVE_FILE_OBJECT = 'remove_file_object',
  REMOVE_ONLINE_FOR_SPINNING = 'remove_online_for_spinning',
}

