//@ts-nocheck
import {
      Button,
      Table,
      Tooltip
} from "antd";
import React, {useImperativeHandle, useMemo} from "react";
import {Day4CustomFC} from "../../types";
import './EntityDataTableView.less';
import {BCItem, IObject} from "./typings";
import {
      useTableViewState
} from "./useTableView";
import CreateEntityData from "../ButtonCreateEntityData";
import UploadCsvData from "../ButtonUploadCSV";
import DynamicBreadCrumb from "./breadcrumb/DynamicBreadCrumb";
import {Key} from "antd/lib/table/interface";
import DrawerFormComponent from "./searchDrawer/DrawerForm";

interface Props {
      namespace: string,
      entity: string
      filter: string
      onItemEditable?: true,
      pageSize?: number
      newCreateView?: boolean
      uploadView?: boolean
      deleteView?: boolean
      searchView?: boolean
      breadCrumbView?: boolean
      onSearchHandle?:() => void
      tableRowSelection?:{
            selectionType:string,
            onChange:(selectedRowKeys: Key[], selectedRows: any[])=>void,
            getCheckboxProps:(selectedRowKeys:string[], selectedRecord:IObject) => void
      }

}


const EntityDataTableView: Day4CustomFC<Props> = ({
                                                        namespace,
                                                        entity,
                                                        filter,
                                                        onItemEditable = true,
                                                        pageSize = 10,
                                                        newCreateView = true,
                                                        uploadView = true,
                                                        deleteView = true,
                                                        searchView = true,
                                                        breadCrumbView = true,
                                                        onSearchHandle=undefined,
                                                        tableRowSelection= {
                                                              selectionType: 'checkbox',
                                                              onChange: undefined,
                                                              getCheckboxProps:undefined
                                                        },
                                                  }, ref) => {

      const tableState = useTableViewState(namespace, entity, filter, pageSize)

      useImperativeHandle(ref, () => ({
            onHandleRowSelection:(selectedKeys, selectedRows) =>{
                  tableState.onRowSelectionChange({
                        selectedRowKeys: selectedKeys,
                        selectedRows: selectedRows
                  })
            },
      }))

      const myColumns = useMemo(
          () => {
                if (tableState.dataSource && tableState.dataSource.columns && tableState.dataSource.columns.length > 0) {
                      const columns = tableState.dataSource.columns
                      return (columns || []).map(column => (
                          {
                                title: column.title,
                                dataIndex: column.dataIndex,
                                key: column.key,
                                // width: colWidth,
                                ellipsis: {
                                      showTitle: false,
                                },
                                onCell: (record: IObject) => ({
                                      onDoubleClick: () => {
                                            if (column.dataType !== 'relation' && onItemEditable === true) {
                                                  tableState.modifyRecord(record)
                                            }
                                      },
                                }),

                                render: (text: any, record: IObject, index) => {
                                      return column.dataType === 'relation' && text.mappingCount > 0 ? (
                                          <a
                                              onClick={() => {
                                                    linkDimensionEntity(text.name, record)
                                              }}
                                          >
                                                {text.displayName}
                                          </a>
                                      ) : (

                                          <Tooltip
                                              placement="topLeft"
                                              title={column.dataType === 'relation' ? text.name : text}
                                          >
                                                {column.dataType === 'relation' ? text.name : text}
                                          </Tooltip>

                                      );
                                },
                          }
                      ))
                }
          },
          [tableState.dataSource],
      );

      const linkDimensionEntity = (col: string, record: any) => {
            if (breadCrumbView) {
                  tableState.pushBreadCrumbItem(col, record)
            }
      }

      const onRefreshTableView = (r: boolean) => {
            if (r) {
                  tableState.onRefreshTableView()
            }
      }

      const popBreadCrumbItem = (item: BCItem) => {
            if (item) {
                  tableState.popBreadCrumbItem(item)
            }
      }

      const getRowSelection = () => {
            if (tableState.createEntityDataWriteable && tableRowSelection) {
                  return {
                        selectedRowKeys: [...tableState.rowSelection.selectedRowKeys],
                        type:tableRowSelection.selectionType,
                        getCheckboxProps: (record:IObject) => {
                              if (tableRowSelection.getCheckboxProps) {
                                    // {disabled:false/true}
                                    return tableRowSelection.getCheckboxProps(tableState.rowSelection.selectedRowKeys, record)
                              }else{
                                    return {
                                          disabled: false
                                    }
                              }
                        },
                        onChange: (selectedRowKeys: Key[], selectedRows: IObject) => {

                              if (tableRowSelection && tableRowSelection.onChange) {
                                    tableRowSelection.onChange(selectedRowKeys, selectedRows)
                              }

                              tableState.onRowSelectionChange({
                                    selectedRowKeys: selectedRowKeys,
                                    selectedRows: selectedRows
                              })

                        }
                  }
            }
            return undefined


      }

      return (

          <>
                      <div style={{marginBottom: 10, display: "inline-block"}}>

                            {
                                  newCreateView ?
                                      <div style={{display: "inline-block", marginLeft: 0}}>
                                            <CreateEntityData namespace={namespace}
                                                              entity={tableState.metaEntity}
                                                              relationships={tableState.relationships}
                                                              disabled={!tableState.createEntityDataWriteable}
                                                              modifyEntityRecord={tableState.modifyEntityRecord}
                                                              resetModifyEntityRecord={() => {
                                                                    tableState.resetModifyEntityRecord()
                                                              }}
                                                              onRefreshTableView={(r: boolean) => onRefreshTableView(r)}/>
                                      </div>
                                      :
                                      undefined
                            }

                            {
                                  uploadView ?
                                      <div style={{display: "inline-block", marginLeft: 20}}>
                                            <UploadCsvData namespace={namespace}
                                                           entity={tableState.metaEntity}
                                                           relationships={tableState.relationships}
                                                           disabled={!tableState.uploadEntityDataWriteable}
                                                           onRefreshTableView={(r: boolean) => onRefreshTableView(r)}/>
                                      </div>
                                      :
                                      undefined
                            }

                            {
                                  deleteView ?
                                      <div style={{display: "inline-block", marginLeft: 20}}>
                                            <Button type={"primary"}
                                                    onClick={() => {
                                                              tableState.onDeleteSelectedRows()
                                                    }}
                                                    disabled={!tableState.createEntityDataWriteable}>{'删除'}</Button>
                                      </div>
                                      :
                                      undefined
                            }

                            {/*{*/}
                            {/*      searchView ?*/}
                            {/*          <div style={{display: "inline-block", marginLeft: 20}}>*/}
                            {/*                <Button type={"primary"}*/}
                            {/*                        onClick={() => {*/}
                            {/*                              console.log("show drawer ....")*/}
                            {/*                              if (onSearchHandle) {*/}
                            {/*                                    onSearchHandle() // drawer由其他组件控制显式或隐藏*/}
                            {/*                              }else{*/}
                            {/*                                    tableState.drawerVisibleFunc(!tableState.drawerVisible)*/}
                            {/*                              }*/}

                            {/*                        }}*/}
                            {/*                        disabled={false}>{'搜素'}</Button>*/}
                            {/*          </div>*/}
                            {/*          :*/}
                            {/*          undefined*/}
                            {/*}*/}

                      </div>

                      {/*维度列跳转导航*/}
                      {
                            breadCrumbView ?
                                <div>
                                      <DynamicBreadCrumb bcItems={tableState.breadCrumbItems}
                                                         popBreadCrumbItem={(item: BCItem) => {
                                                               popBreadCrumbItem(item)
                                                         }}/>
                                </div>
                                :
                                undefined
                      }

                      <div className={'container'}>

                            <Table
                                // style = {{wordBreak: 'break-all'}}
                                loading={tableState.loading}
                                dataSource={tableState.dataSource.records}
                                columns={myColumns}
                                rowSelection={
                                      getRowSelection()
                                }
                                scroll={{x: 'max-content'}}
                                pagination={{...tableState.pagination}}
                                onChange={(pagination,
                                           filters,
                                           sorter, extra) =>
                                    tableState.pagination.onHandleChange(pagination, filters, sorter)}

                            />

                      </div>


                      {
                            tableState.drawerVisible ?
                                <DrawerFormComponent metaEntity={tableState.metaEntity}
                                                     drawerVisible={tableState.drawerVisible}
                                                     setDrawerVisible={(v: boolean) => tableState.drawerVisibleFunc(v)}
                                                     onSearchSubmit={() => {

                                                     }}
                                />
                                :
                                undefined
                      }


          </>
      );


}

export default React.forwardRef(EntityDataTableView)
