/* eslint-disable no-continue */
//@ts-nocheck
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {UploadOutlined, PlusOutlined} from '@ant-design/icons';
import {
      Form,
      Input,
      DatePicker,
      Button,
      Select, InputNumber,
      Upload, UploadProps,
      Tooltip,
      Spin,
      Tabs,
} from 'antd';
import './DataForm.less';
import {FormItem, matchAnnotationOfResourceType, matchRelationshipEntity} from "./useUpsertEntityData";
import {useDataFormState} from "./useDataForm";
import {UploadFile} from "antd/lib/upload/interface";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";
import SelectWithRelationship from "./SelectWithRelationship";
import moment from "moment";
import {ACTION_TYPE} from "./typings";


interface IDataFormProps {
      namespace: string
      entity: EntityType
      relationships: RelationType[],
      formItems?: FormItem[]
      curRecord?: any
      setVisible: (v: boolean) => void
      formSubmit: (v: { [index: string]: any }) => void
}


const DataForm: React.FC<IDataFormProps> = props => {
      const {
            namespace,
            entity,
            relationships,
            formItems = [],
            curRecord,
            setVisible,
            formSubmit,
      } = props;
      const [form] = Form.useForm();
      const {TabPane} = Tabs;
      const {Option, OptGroup} = Select;
      const {TextArea} = Input;

      const dataFormState = useDataFormState(namespace, entity, relationships, formItems)


      useEffect(() => {
            if (curRecord) {
                  const fieldsValue = {}
                  for (let formItem of dataFormState.formItems) {
                        if (formItem.type !== "relation" && formItem.type !== "files") {
                              const isExitDefaultValue = formItem.child.defaultValues && formItem.child.defaultValues.length > 0 && formItem.child.defaultValues[0].value
                              if (isExitDefaultValue) {
                                    if (formItem.type === "date") {
                                          fieldsValue[formItem.name] = moment(formItem.child.defaultValues[0].value, 'YYYY-MM-DD')
                                    } else if (formItem.type === 'datetime') {
                                          fieldsValue[formItem.name] = moment(formItem.child.defaultValues[0].value, 'YYYY-MM-DD HH:mm:ss')
                                    } else {
                                          fieldsValue[formItem.name] = formItem.child.defaultValues[0].value
                                    }

                              }
                        }
                  }
                  form.setFieldsValue({...fieldsValue})
            }
      }, [curRecord])

      //提交表单且数据验证成功后回调事件
      // 处理 insert 或 update 操作
      const onFinish = () => {
            form
                .validateFields()
                .then(values => {
                      if (matchAnnotationOfResourceType(entity) && !curRecord) {
                            dataFormState.submit(form, {...values}, formSubmit)
                      } else {
                            formSubmit({...values})
                      }

                })
      }

      // 通知父组件,关闭模态框
      const handleCancel = () => {
            setVisible(false);
      }

      //提交表单
      const handleOK = () => {
            form.submit()
      }

      const uploadProps = (formItem: FormItem): UploadProps => {
            const fileObjectList = []
            for (let item of dataFormState.formItems) {
                  if (item.name === formItem.name) {
                        for (let v of item.child.values) {
                              fileObjectList.push(v.value)
                        }
                  }
            }
            return {
                  onPreview: async file => {
                        // if (!file.url && !file.preview) {
                        //       file.preview = await getBase64(file.originFileObj);
                        // }
                  },
                  multiple: true,
                  showUploadList: true,
                  listType: "picture",
                  fileList: fileObjectList,
                  customRequest: (options: UploadRequestOption) => {
                        dataFormState.customRequest(options, formItem)
                  },
                  beforeUpload: (file) => {
                        return dataFormState.beforeUpload(file)
                  },
                  onRemove: (file: UploadFile) => {
                        dataFormState.removeFile(file)
                  },
                  itemRender: (originNode, file, fileList, actions) => {
                        return file.status === 'error' ?
                            <Tooltip title='上传失败'>{originNode.props.children}</Tooltip> : originNode
                  },
                  progress: {
                        strokeColor: {
                              '0%': '#108ee9',
                              '100%': '#87d068',
                        },
                        strokeWidth: 2,
                        format: percent => {
                              return `${parseFloat(percent.toFixed(2))}%`
                        },
                  },

            }
      }

      function onHandleSelectedValuesOfRelationship(fi: FormItem) {
            dataFormState.formItems.filter((i) => i.name === fi.name)
                .map((i) => {
                      i.child = fi.child
                      i.fromFormItem = fi.fromFormItem
                })
      }

      function renderItem(formItem: FormItem) {
            const defaultValues = formItem.child.defaultValues && formItem.child.defaultValues.length > 0 && formItem.child.defaultValues[0].value
            const value = formItem.child.values && formItem.child.values.length > 0 && formItem.child.values[0].value


            if (formItem.name.trim().toLocaleLowerCase() === "id") {
                  if (formItem.type === 'int') {
                        return <InputNumber key={formItem.child.name}
                                            name={formItem.child.name}
                                            disabled={!formItem.editable}
                                            addonAfter={formItem.child.additional}
                                            value={value ? formItem.child.values[0].value : ''}
                                            defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                        />
                  }
                  if (formItem.type === 'string') {
                        return <Input
                            key={formItem.child.name}
                            name={formItem.child.name}
                            disabled={!formItem.editable}
                            addonAfter={formItem.child.additional}
                            value={value ? formItem.child.values[0].value : ''}
                            defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                        />
                  }
            }

            if (formItem.type === 'int') {
                  return (
                      <InputNumber key={formItem.child.name}
                                   name={formItem.child.name}
                                   disabled={!formItem.editable}
                                   value={value ? formItem.child.values[0].value : ''}
                                   defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                      />
                  )
            } else if (formItem.type === 'float') {
                  return (
                      <InputNumber
                          key={formItem.child.name}
                          name={formItem.child.name}
                          disabled={!formItem.editable}
                          value={value ? formItem.child.values[0].value : ''}
                          defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                          step="0.0"
                          stringMode
                      />
                  )
            } else if (formItem.type === 'date') {
                  return (
                      <DatePicker
                          format={"YYYY-MM-DD"}
                          key={formItem.child.name}
                          name={formItem.child.name}
                          disabled={!formItem.editable}
                          value={value ? formItem.child.values[0].value : ''}
                          defaultValue={defaultValues ? moment(formItem.child.defaultValues[0].value, 'YYYY-MM-DD') : ''}

                      />
                  )
            } else if (formItem.type === 'datetime') {
                  return (
                      <DatePicker
                          showTime
                          format="YYYY-MM-DD HH:mm:ss"
                          key={formItem.child.name}
                          name={formItem.child.name}
                          disabled={!formItem.editable}
                          value={value ? formItem.child.values[0].value : ''}
                          defaultValue={defaultValues ? moment(formItem.child.defaultValues[0].value, 'YYYY-MM-DD HH:mm:ss') : ''}
                      />
                  )
            } else if (formItem.type === 'bool') {
                  return (
                      <Select key={formItem.child.name}
                              disabled={!formItem.editable}
                              defaultValue={defaultValues ? `${formItem.child.defaultValues[0].value}` : ''}
                      >
                            <Option key={"true"} value={"true"}>true</Option>
                            <Option key={"false"} value={"false"}>false</Option>
                      </Select>

                  )

            } else if (formItem.type === 'relation') {
                  let relationshipEntity = matchRelationshipEntity(relationships, entity, formItem.name)
                  return (<SelectWithRelationship
                      metaEntity={entity}
                      relationEntity={relationshipEntity}
                      relationships={relationships}
                      formItem={formItem}
                      selectMode="multiple"
                      modifyRecord={curRecord}
                      onHandleSelectedValues={(formItem) => onHandleSelectedValuesOfRelationship(formItem)}

                  />)
            } else if (formItem.type === 'text' || formItem.type === "json" || formItem.type === 'enum') {
                  return (<TextArea
                      autoSize={{minRows: 2, maxRows: 6}}
                      value={value ? formItem.child.values[0].value : ''}
                      defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                  />)
            } else if (formItem.type === 'files') {
                  return (<div>
                        <Spin spinning={dataFormState.spinning}>
                              <Upload {...uploadProps(formItem)} >
                                    <Button icon={<UploadOutlined/>}
                                            disabled={formItem.child.values.length === 10}>{formItem.child.name}</Button>
                              </Upload>
                        </Spin>
                  </div>)
            } else {
                  return (
                      <Input
                          key={formItem.child.name}
                          name={formItem.child.name}
                          disabled={!formItem.editable}
                          value={value ? formItem.child.values[0].value : ''}
                          defaultValue={defaultValues ? formItem.child.defaultValues[0].value : ''}
                      />
                  )
            }
      }

      return (
          <>
                <div className={'content'} >

                      <Form

                          labelCol={{ flex: '110px' }}
                          labelAlign="right"
                          labelWrap
                          wrapperCol={{ flex: 1 }}
                          name="data"
                          form={form}
                          onFinish={onFinish}
                          preserve={false}
                      >
                            <Tabs defaultActiveKey={1} centered>
                                  <TabPane tab={"基本属性"} key={1}>
                                        {

                                              dataFormState.formItems.filter(i => i.type !== 'relation').map((formItem: FormItem, index: number) => {
                                                    return (
                                                        <Form.Item key={formItem.name}
                                                                   name={formItem.name}
                                                                   label={formItem.label}
                                                                   rules={[{
                                                                         required: formItem.rules[0].required ? formItem.rules[0].required : false,
                                                                         message: formItem.rules[0].message ? formItem.rules[0].message : ''
                                                                   }]}>
                                                              {
                                                                    renderItem(formItem)
                                                              }
                                                        </Form.Item>
                                                    )
                                              })
                                        }
                                  </TabPane>
                                  <TabPane tab={"访问属性"} key="2">
                                        {
                                              dataFormState.formItems.filter(i => i.type === 'relation').map((formItem: FormItem, index: number) => {
                                                    return (
                                                        <Form.Item key={formItem.name}
                                                                   name={formItem.name}
                                                                   label={formItem.label}
                                                                   rules={[{
                                                                         required: formItem.rules[0].required ? formItem.rules[0].required : false,
                                                                         message: formItem.rules[0].message ? formItem.rules[0].message : ''
                                                                   }]}>
                                                              {
                                                                    renderItem(formItem)
                                                              }
                                                        </Form.Item>
                                                    )
                                              })
                                        }
                                  </TabPane>
                            </Tabs>
                      </Form>

                </div>
                <div className={'footer'}>
                      <Button type="default" onClick={handleCancel}>
                            取消
                      </Button>
                      <Button type="primary" onClick={handleOK}>
                            {curRecord ? '更新' : '确定'}
                      </Button>
                </div>

          </>
      );
};

export default DataForm;
