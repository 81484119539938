import {ACTION_TYPE, BCItem, IAction, IObject, IState} from "./typings";
import {RelationType} from "../../api/types/RelationTypes";
import {EntityType} from "../../api/types/EntityTypes";
import {DataSourceType} from "../../api/types/DataSourceTypes";
import {IPageInfo} from "../../api/types/EntityDataTypes";

function tableViewReducer(state: IState, action: IAction): IState {
      const {type, payload} = action;
      switch (type) {
            case ACTION_TYPE.SETTING_ENTITY:
                  return {
                        ...state,
                        entity: payload as string
                  }
            case ACTION_TYPE.SETTING_FILTER:
                  return {
                        ...state,
                        filter: payload as string
                  }
            case ACTION_TYPE.SETTING_RELATIONSHIPS:
                  return {
                        ...state,
                        relationships: payload as RelationType[]
                  }
            case ACTION_TYPE.SETTING_NAMESPACE_DATASOURCES:
                  const ds = payload as DataSourceType
                if (ds.id){
                      state.metaDataSources.set(ds.id, ds)
                }
                  return {
                        ...state,
                        metaDataSources: state.metaDataSources
                  }
            case ACTION_TYPE.SETTING_METAENTITY:
                  return {
                        ...state,
                        metaEntity: payload as EntityType
                  }
            case ACTION_TYPE.SETTING_CREATE_ENTITY_DATA_WRITEABLE:
                  return {
                        ...state,
                        createEntityDataWriteable: payload as boolean
                  }
            case ACTION_TYPE.SETTING_UPLOAD_ENTITY_DATA_WRITEABLE:
                  return {
                        ...state,
                        uploadEntityDataWriteable: payload as boolean
                  }
            case ACTION_TYPE.SETTING_MODIFY_ENTITY_RECORD:
                  return {
                        ...state,
                        modifyEntityRecord: payload
                  }
            case ACTION_TYPE.SETTING_LOADING:
                  return {
                        ...state,
                        loading: payload as boolean
                  }
            case ACTION_TYPE.SETTING_PAGINATION:
                  return {
                        ...state,
                        pagination: payload
                  }
            case ACTION_TYPE.SETTING_PUSH_CURSORS:
                  let pageInfo = payload as IPageInfo
                  state.cursors.set(pageInfo.current, pageInfo)
                  return {
                        ...state,
                        cursors: state.cursors
                  }
            case ACTION_TYPE.SETTING_POP_CURSORS:
                  let pageInfo1 = payload as IPageInfo
                  state.cursors.delete(pageInfo1.current)
                  return {
                        ...state,
                        cursors: state.cursors
                  }
            case ACTION_TYPE.SETTING_CHANGE_PAGINATION:
                  return {
                        ...state,
                        changePagination: payload as boolean
                  }
            case ACTION_TYPE.SETTING_DATASOURCE:
                  return {
                        ...state,
                        dataSource: payload
                  }
            case ACTION_TYPE.SETTING_TIMESERIES:
                  return {
                        ...state,
                        timeSeries: payload as boolean
                  }
            case ACTION_TYPE.SETTING_BREADCRUMB_ITEMS:

                  return {
                        ...state,
                        offsetMappingPageGroup: new Map<number, number[]>(),
                        tsEstimatePageData: new Map<number, any>(),
                        breadCrumbItems: payload as BCItem[]
                  }
            case ACTION_TYPE.SETTING_TS_ESTIMATE_PAGE_DATA:
                  return {
                        ...state,
                        tsEstimatePageData: payload
                  }
            case ACTION_TYPE.SETTING_TS_OFFSET_MAPPING_PAGE_GROUP:
                  return {
                        ...state,
                        offsetMappingPageGroup: payload
                  }
            case ACTION_TYPE.SETTING_IS_POP_BREAD_CRUMB_ITEM:
                  return {
                        ...state,
                        isPopBreadCrumbItem: payload as boolean
                  }
            case ACTION_TYPE.SETTING_IS_PUSH_BREAD_CRUMB_ITEM:
                  return {
                        ...state,
                        isPushBreadCrumbItem: payload as boolean
                  }
            case ACTION_TYPE.SETTING_DRAWER_VISIBLE:
                  return {
                        ...state,
                        drawerVisible: payload as boolean
                  }
            case ACTION_TYPE.SETTING_ROW_SELECTED_DATA:
                  return {
                        ...state,
                        rowSelection: payload
                  }
            default:
                  return state;
      }
}

export {
      tableViewReducer
}
