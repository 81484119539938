//@ts-nocheck
import {Button} from "antd";
import {recordReducer} from "./reducer";
import React, {useEffect, useReducer} from "react";
import {ACTION_TYPE, IState} from "./typings";
import {EntityType} from "../../api/types/EntityTypes";
import entityApi from "../../api/requests/entityReqs";
import relationApi from "../../api/requests/relationReqs";
import {RelationType} from "../../api/types/RelationTypes";
import UpsertModal from "./UpsertModal"
import {
      CollectFormItems,
      GenerationDimensionMappingEntity,
      GenerationEntityPrimariesColumns,
      GenerationRelationshipRecords,
      generationCreateDataExpression,
      matchAnnotationOfResourceType,
      GenerationQueryRelationshipFilter,
      GenerationQueryRelatedRecordEL,
      GenerationQueryRelatedRecordPromise,
      RelationshipRecords,
      generationUpdateDataExpression,
      generationIdentityExpression,
} from "./useUpsertEntityData";
import {EntityDataTypes} from "../../api/types/EntityDataTypes";
import {IObject} from "../EntityDataTableView/typings";

interface Props {
      namespace: string
      entity: EntityType
      relationships: RelationType[]
      disabled: boolean
      modifyEntityRecord: IObject
      onRefreshTableView: (v: boolean) => void
      resetModifyEntityRecord: () => void
}


const initialState = (): IState => {
      return {
            record: undefined,
            relationships: [],
            modalVisible: false,
            object_storage: false,
            relationshipRecords:[],
      }

}

const CreateEntityData: React.FC<Props> = ({
                                                 namespace,
                                                 entity,
                                                 relationships,
                                                 disabled,
                                                 modifyEntityRecord,
                                                 onRefreshTableView,
                                                 resetModifyEntityRecord,
                                           }) => {

      const [state, dispatch] = useReducer(recordReducer, initialState())

      // 修改记录, 预先把当前实体已确定关联的记录加载出来
      // const queryRelationshipRecords = (showModelVisibleFunc:()=>void) => {
      //       if (entity && relationships && modifyEntityRecord) {
      //             const dimMappingEntities = GenerationDimensionMappingEntity(entity, relationships);
      //             if (dimMappingEntities.size == 0){
      //                   showModelVisibleFunc()
      //                   return
      //             }
      //
      //             let queryPromises: Map<any, Promise<EntityDataTypes>> = new Map<any, Promise<EntityDataTypes>>()
      //             for (let [dimColumn, dimMappingEntity] of dimMappingEntities) {
      //                   if (dimMappingEntity.state === "PUBLISH_DONE") {
      //                         const p = GenerationQueryRelatedRecordPromise(modifyEntityRecord, entity, dimMappingEntity, dimColumn, relationships)
      //                         if (p && p.length > 0) {
      //                               queryPromises.set(p[0], p[1])
      //                         }
      //                   }
      //             }
      //
      //             // 存在关联，但关联未发布
      //             if (queryPromises.size == 0)  {
      //                   showModelVisibleFunc()
      //                   return
      //             }
      //
      //
      //             //处理查询. 查询与当前实体有关联的记录
      //             Promise.all<any>(queryPromises).then((queryResult) => {
      //                   let resultParsePromises:Promise[] = []
      //                   for (let v of queryResult) {
      //                         resultParsePromises.push(v[1])
      //                   }
      //                   // 处理结果
      //                   Promise.all<EntityDataTypes>(resultParsePromises).then((results) => {
      //                         let relationshipRecordsList:RelationshipRecords[] = []
      //                         for (let i = 0; i < results.length; i++) {
      //                               const queryTargetInfo = queryResult[i][0]
      //                               const result = results[i]
      //
      //                               let formatType:string = queryTargetInfo['formatType']
      //                               let queryTargetEntity:EntityType = queryTargetInfo['queryTargetEntity']
      //                               let currentEntity:EntityType = queryTargetInfo['entity']
      //                               let relationEntity:EntityType = queryTargetInfo['relationEntity']
      //                               let accessAttributeName:string = queryTargetInfo['accessAttributeName']
      //
      //                               if (formatType === '列表' && queryTargetEntity.name === relationEntity.name) {
      //                                     console.log("列表-结果 => ", result)
      //                                     // 根据查询结果，封装 RelatedRecords(已关联的记录)
      //                                     if (result.data[queryTargetEntity.name] && Object.keys(result.data[queryTargetEntity.name]).length > 0) {
      //                                           let rsr = new RelationshipRecords()
      //                                           rsr.name = accessAttributeName
      //                                           rsr.relRecords = result.data[queryTargetEntity.name]
      //                                           relationshipRecordsList.push(rsr)
      //
      //                                     }
      //                               }
      //
      //                               if (formatType === '对象' && currentEntity.name === queryTargetEntity.name) {
      //                                     // area -> factory（dimension）
      //                                     // 其表达式
      //                                     // {
      //                                     //       area (filter:{id:1}){
      //                                     //          id,
      //                                     //          factory {
      //                                     //                id
      //                                     //          }
      //                                     //       }
      //                                     // }
      //
      //                                     console.log("对象-结果 => ", result)
      //                                     // 根据查询结果，封装 RelatedRecords(已关联的记录)
      //                                     if (result.data[queryTargetEntity.name] && Object.keys(result.data[queryTargetEntity.name]).length > 0) {
      //                                           let rsr = new RelationshipRecords()
      //                                           const primaryInfoOfRelationship = result.data[entity.name][0][relationEntity.name]
      //                                           // 判断是否关联
      //                                           const primaryCols = GenerationEntityPrimariesColumns(relationEntity)
      //                                           let primaryNullOfRelation = false
      //                                           for (let col of primaryCols) {
      //                                                 if (!primaryInfoOfRelationship[col]) {
      //                                                       primaryNullOfRelation = true
      //                                                       break
      //                                                 }
      //                                           }
      //                                           rsr.name = accessAttributeName
      //                                           rsr.relRecords = primaryNullOfRelation ? [] : [primaryInfoOfRelationship]
      //                                           relationshipRecordsList.push(rsr)
      //                                     }
      //                               }
      //                         }
      //
      //                         dispatch({
      //                               type: ACTION_TYPE.INIT_RELATIONSHIP_RECORDS,
      //                               payload: relationshipRecordsList
      //                         })
      //
      //                         showModelVisibleFunc()
      //
      //                   })
      //             }).catch((err: Error) => {
      //                   console.log(err)
      //             })
      //       }
      // }

      // 修改记录
      useEffect(() => {
            if (modifyEntityRecord) {
                  // queryRelationshipRecords(()=> {
                  //       dispatch({
                  //             type: ACTION_TYPE.MODAL_VISIBLE,
                  //             payload: true
                  //       })
                  // })

                  dispatch({
                        type: ACTION_TYPE.MODAL_VISIBLE,
                        payload: true
                  })
            }

      }, [modifyEntityRecord])


      // 针对新建或更新，收集FormItems
      useEffect(() => {
            if (state.modalVisible) {
                  const formItems = CollectFormItems(entity, relationships, state.relationshipRecords, modifyEntityRecord)
                  dispatch({
                        type: ACTION_TYPE.INIT_FORM_ITEMS,
                        payload: formItems
                  })
            }

      }, [state.modalVisible])


      // 显式模态框,添加记录
      const createData = () => {
            dispatch({
                  type: ACTION_TYPE.MODAL_VISIBLE,
                  payload: true
            })

      }


      //控制模态框的显式
      const setModalVisible = (v: boolean) => {
            dispatch({
                  type: ACTION_TYPE.MODAL_VISIBLE,
                  payload: v
            })

      }

      //模态框关闭之后回调
      const modalAfterClose = () => {
            resetModifyEntityRecord()
            dispatch({
                  type: ACTION_TYPE.INIT_FORM_ITEMS,
                  payload: []
            })

      }

      const createEntityRecord = (values:IObject) => {
            const expression: string = generationCreateDataExpression(entity, relationships, state.formItems, values)
            // 文件对象类型
            if (matchAnnotationOfResourceType(entity)) {
                  onRefreshTableView(true)
                  setModalVisible(false)
                  return
            }

            // 表单中无填写任何内容
            if (expression === undefined || expression === '') {
                  setModalVisible(false)
                  return
            }

            // 其他类型
            entityApi.fetchCreateEntityDataType(
                entity,
                expression,
                GenerationEntityPrimariesColumns(entity))
                .then((resp) => {
                      // 更新tableView
                      onRefreshTableView(true)
                      setModalVisible(false)
                })
                .catch((err: Error) => {
                      console.log(err)
                })
      }


      const updateEntityRecord = (values:IObject) => {

            const identityExpByEntity: string = generationIdentityExpression(entity, values)
            const exps = generationUpdateDataExpression(identityExpByEntity, entity, relationships, state.formItems, values)

            // 其他类型
            entityApi.fetchUpdateRelationEntityDataType(
                entity,
                exps[0],
                exps[1],
                exps[2],
            ).then((resp) => {
                      // 更新tableView
                      onRefreshTableView(true)
                      setModalVisible(false)
                })
                .catch((err: Error) => {
                      console.log(err)
                })
      }


      //创建记录
      function setFormSubmit(values: { [p: string]: any }) {
            // value: {
            //     "id": "x",
            //     "name": "g",
            //     "line": "{\"id\":\"ALypKi2nR\"}"
            // }
            if (modifyEntityRecord) {
                  updateEntityRecord(values)
            }else{
                  createEntityRecord(values)
            }


      }

      return (
          <>
                <div>
                      <Button type={"primary"} onClick={createData} disabled={disabled}>{'新建'}</Button>
                      <UpsertModal
                          namespace={namespace}
                          entity={entity}
                          curRecord={modifyEntityRecord}
                          relationships={relationships}
                          setVisible={(v) => setModalVisible(v)}
                          formSubmit={(v) => setFormSubmit(v)}
                          afterClose={() => modalAfterClose()}
                          visible={state.modalVisible}
                          formItems={state.formItems}
                      />
                </div>
          </>
      )
}


export default CreateEntityData;
