// @ts-nocheck
import entityApi from "../../api/requests/entityReqs";
import {Column, DataEntityTypes} from "./dataEntity";
import {Annotation, EntityAttributeType, EntityType} from "../../api/types/EntityTypes";
import {DataSource} from "./dataEntity"

import {ACTION_TYPE, BCItem, IObject, IState} from "./typings";
import {RelationType} from "../../api/types/RelationTypes";
import {
      EntityConnectionDataTypes,
      EntityDataTypes, IPageInfo, PageInfo, RecordNode, TsConfig
} from "../../api/types/EntityDataTypes";
import {useEffect, useReducer} from "react";
import {tableViewReducer} from "./reducer";
import relationApi from "../../api/requests/relationReqs";
import {message, Modal, notification, TablePaginationConfig} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {Guid} from "guid-typescript";
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import {META_ENTITY_BIND_DATASOURCE, RESOURCE_TYPE} from "../../api/types/CommonTypes";
import {DataSourceType} from "../../api/types/DataSourceTypes";
import {
      generationBatchedDeleteIdentityExpression,
      generationIdentityExpression
} from "../ButtonCreateEntityData/useUpsertEntityData";


export function BuildDataSource(metaEntity: EntityType, dataEntity: DataEntityTypes): DataSource {

      /**
       * columns = [
       {
        title: id
        dataindex: id
        key:id
     },
       {
      title: name
      dataindex: name
      key:name
      }
       ]
       */

      if (!dataEntity || !dataEntity) {
            return new DataSource()
      }

      const schema = dataEntity.schema;
      const columns = new Array<Column>()

      const attrs = metaEntity.attributes
      if (!attrs || attrs.length == 0) {
            return new DataSource()
      }

      for (let sha of schema) {
            let column = new Column()
            for (let attr of attrs) {
                  if (attr.name == sha.name) {
                        column.key = attr.name
                        column.dataIndex = attr.name
                        column.title = attr.display ? attr.display : attr.name
                        column.dataType = attr.type
                        column.dataTypeOptions = attr.type_options
                        break
                  }
            }
            columns.push(column)
      }

      // 追加维度列
      attrs.map((item, index) => {
            if (item.type === 'relation') {
                  let column = new Column()
                  column.key = item.name
                  column.dataIndex = item.name
                  column.title = item.display ? item.display : item.name
                  column.dataType = item.type
                  column.dataTypeOptions = item.type_options
                  columns.push(column)
            }
      })


      /**
       data = [
       {
      key:0
      id: xxx
      name: 产线1
     },
       {
        key:1
        id: yyyy
        name: 产线2
     }

       ]
       */

      const rows = dataEntity.rows // [['ALypKi2nR', '产线1'], ['bcxfKi2n3', '产线2']]
      const source = []
      for (let ri in rows) {
            let cell = new Map()
            cell.set('key', ri)
            for (let ci in columns) { // ['ALypKi2nR', '产线1']
                  let column = columns[ci]
                  if (column.dataType === 'relation') {
                        cell.set(columns[ci].key, columns[ci].key)
                  } else {
                        cell.set(columns[ci].key, rows[ri][ci])
                  }
            }
            // 把Map 转换为 对象
            source.push(Object.fromEntries(cell.entries()))
      }

      const dataSource = new DataSource();
      dataSource.records = source
      dataSource.columns = columns
      return dataSource
}

export function queryResourceAnnotation(metaEntity:EntityType):Annotation {
      if (metaEntity.annotations && metaEntity.annotations.length > 0) {
            for (let ann of metaEntity.annotations) {
                  if (ann.annotation_type_name === 'RESOURCE_TYPE') {
                        return ann
                  }
            }
      }
}
export function matchRelationshipEntity(relationships: RelationType[], metaEntity: EntityType, dimensionName: string) {
      for (let relation of relationships) {
            if (relation.state === 'PUBLISH_DONE' && relation.object_type_name1 === metaEntity.name && relation.attribute_name1 === dimensionName) {
                  return relation.object_type2
            }
            if (relation.state === 'PUBLISH_DONE' && relation.object_type_name2 === metaEntity.name && relation.attribute_name2 === dimensionName) {
                  return relation.object_type1
            }
      }
}

export function matchRelationship(relationships: RelationType[], metaEntity: EntityType, dimensionName: string) {
      for (let relation of relationships) {
            if (relation.state === 'PUBLISH_DONE' && relation.object_type_name1 === metaEntity.name && relation.attribute_name1 === dimensionName) {
                  return relation
            }
            if (relation.state === 'PUBLISH_DONE' && relation.object_type_name2 === metaEntity.name && relation.attribute_name2 === dimensionName) {
                  return relation
            }
      }
}

function queryEntityColumns(relationships: RelationType[], metaEntity: EntityType): string[] {
      let queryColumns: string[] = []
      for (let attr of metaEntity.attributes) {
            if (attr.state && (metaEntity.is_external ? true : attr.state === 'PUBLISH_DONE')) {
                  if (attr.type === 'relation') {
                        let relationshipEntity = matchRelationshipEntity(relationships, metaEntity, attr.name)
                        let cols: string[] = []
                        if (relationshipEntity && relationshipEntity.attributes) {
                              for (let attr of relationshipEntity.attributes) {
                                    if (attr.is_primary) {
                                          cols.push(attr.name)
                                    }
                              }
                              let dimColumnExpression = `${attr.name} {${cols.join(" ")}}`
                              queryColumns.push(dimColumnExpression)
                        }

                  } else {
                        queryColumns.push(attr.name)
                  }
            }

      }

      return queryColumns

}

function queryRelationshipPrimaries(dimName: string, relationships: RelationType[], metaEntity: EntityType): EntityAttributeType[] {
      let relationshipEntity = matchRelationshipEntity(relationships, metaEntity, dimName)
      let primaries: EntityAttributeType[] = []
      if (relationshipEntity && relationshipEntity.attributes) {
            for (let attr of relationshipEntity.attributes) {
                  if (attr.is_primary) {
                        primaries.push(attr)
                  }
            }
      }
      return primaries

}

function tableColumns(metaEntity: EntityType): Column[] {

      /**
       * columns = [
       {
        title: id
        dataindex: id
        key:id
     },
       {
      title: name
      dataindex: name
      key:name
      }
       ]
       */

      let columns = new Array<Column>()
      metaEntity?.attributes?.map((item, index) => {
            let column = new Column()
            column.key = item.name
            column.dataIndex = item.name
            column.title = item.display ? item.display : item.name
            column.dataType = item.type
            column.dataTypeOptions = item.type_options
            columns.push(column)

      })

      return columns
}

export const formattedDisplay = (dimensionName: string, metaEntity: EntityType, relationships: RelationType[]) => {

      if (metaEntity && relationships) {
            for (let relation of relationships) {
                  if (relation.object_type_name1 === metaEntity.name && relation.attribute_name1 === dimensionName) {
                        let text: string = ''

                        if (relation.attributes && relation.attributes.length > 0) {
                              return text += "列表"
                        }
                        // o2 外部实体并且覆盖访问属性
                        if (relation.object_type2?.is_external && relation.rewrite_on_attribute_name2_conflict) {
                              return text += "列表"
                        } else {
                              if (relation.relation_multiplicity === 'ONE2MANY') {
                                    return text += "列表"
                              }
                              if (relation.relation_multiplicity === 'ONE2ONE') {
                                    return text += "对象"
                              }
                              if (relation.relation_multiplicity === 'MANY2MANY') {
                                    return text += "列表"
                              }
                        }
                  }

                  if (relation.object_type_name2 === metaEntity.name && relation.attribute_name2 === dimensionName) {
                        let text: string = ''

                        if (relation.attributes && relation.attributes.length > 0) {
                              return text += "列表"
                        }
                        //o1外部实体并且覆盖访问属性
                        if (relation.object_type1?.is_external && relation.rewrite_on_attribute_name1_conflict) {
                              return text += "列表"
                        } else {
                              if (relation.relation_multiplicity === 'ONE2MANY') {
                                    return text += "对象"
                              }
                              if (relation.relation_multiplicity === 'ONE2ONE') {
                                    return text += "对象"
                              }
                              if (relation.relation_multiplicity === 'MANY2MANY') {
                                    return text += "列表"
                              }
                        }
                  }
            }
      }
}

function tableRecord(columns: Column[], node: IObject, keyId: string,
                     checkRelationshipRecord: (dimName: string, dimValues: {}) => boolean,
                     formattedDisplay: (dimName: string) => string): any {

      let row = new Map()
      for (let ci in columns) {
            let column = columns[ci]

            row.set('key', keyId)
            if (column.dataType === 'relation') {
                  // 列表
                  if (node[column.key] && node[column.key] as []
                      && node[column.key].length > 0) {
                        // console.log("列表， node[column.key] =>", node[column.key])
                        row.set(column.key, {
                              name: column.key,
                              displayName: formattedDisplay(column.key),
                              mappingCount: node[column.key].length,
                              dimensionRecord: node[column.key]
                        })
                        // 对象
                  } else if (node[column.key] && node[column.key] as {}
                      && Object.keys(node[column.key]).length > 0
                      && checkRelationshipRecord(column.key, node[column.key])) {
                        // console.log(" column.key =>", column.key,",  length =>", node[column.key])
                        // console.log("对象， node[column.key] =>", node[column.key])
                        row.set(column.key, {
                                  name: column.key,
                                  displayName: formattedDisplay(column.key),
                                  mappingCount: 1,
                                  dimensionRecord: node[column.key]
                            }
                        )
                        // 无关联记录
                  } else {
                        row.set(column.key, {name: "-", mappingCount: 0})
                  }
            } else {
                  if (column.key) {
                        if (column.dataType === 'bool') {
                              row.set(column.key, node[column.key] != null &&  node[column.key] != undefined ? `${node[column.key]}`: '')
                        }else{
                              row.set(column.key, node[column.key])
                        }

                  }
            }
      }

      return row

}

export function BuildTimeSeriesDataSource(relationships: RelationType[], metaEntity: EntityType, dataEntity: EntityDataTypes): DataSource {

      if (!dataEntity || !dataEntity) {
            return new DataSource()
      }

      const nodes = dataEntity[metaEntity.name]
      const columns = tableColumns(metaEntity)

      if (!nodes || nodes.length == 0) {
            return new DataSource()
      }

      const source: IObject[] = []
      nodes.map((node, index) => {
            // 把Map 转换为 对象
            source.push(Object.fromEntries(tableRecord(columns, node, index,
                (dimName: string, dimValues: {}) => {
                      const primaries = queryRelationshipPrimaries(dimName, relationships, metaEntity)
                      let exits: boolean = true
                      for (let primary of primaries) {
                            let dv = dimValues[primary.name]
                            if (dv === '' || dv === null || dv === undefined) {
                                  exits = false
                                  break
                            }
                      }
                      return exits
                },
                (dimName: string) => {
                      let d = formattedDisplay(dimName, metaEntity, relationships)
                      if (d) {
                            return d
                      } else {
                            return dimName
                      }
                }).entries()))
      })


      const dataSource = new DataSource();
      dataSource.records = source
      dataSource.columns = columns
      dataSource.pageInfo = undefined
      return dataSource
}

export function generationKeyForEntityRecord (metaEntity:EntityType, item:IObject,index:number):string {
      const identityValue:string[] = []
      for (let identityAttribute of metaEntity.identity_type.attributes) {
            identityValue.push(`${identityAttribute.name}-${item[identityAttribute.name]}`)
      }
      return identityValue.join("-")
}

function BuildConnectionDataSource(relationships: RelationType[], metaEntity: EntityType, dataEntity: EntityConnectionDataTypes): DataSource {


      if (!dataEntity || !dataEntity) {
            return new DataSource()
      }

      const nodes = dataEntity.edges
      const columns = tableColumns(metaEntity)

      const attrs = metaEntity.attributes
      if (!attrs || attrs.length == 0) {
            return new DataSource()
      }


      /**
       * "edges": [
       {
          "node": {
            "id": "ALypKi2nR",
            "name": "产线1"
          }
        }
       },
       {}
       ]
       */



      const source: IObject[] = []
      nodes.map((item, index) => {
            // 把Map 转换为 对象
            source.push(Object.fromEntries(tableRecord(columns, item.node, generationKeyForEntityRecord(metaEntity, item.node, index),
                (dimName: string, dimValues: {}) => {
                      const primaries = queryRelationshipPrimaries(dimName, relationships, metaEntity)
                      let exits: boolean = true
                      for (let primary of primaries) {
                            let dv = dimValues[primary.name]
                            if (dv === '' || dv === null || dv === undefined) {
                                  exits = false
                                  break
                            }
                      }
                      return exits
                },
                (dimName: string) => {
                      let d = formattedDisplay(dimName, metaEntity, relationships)
                      if (d) {
                            return d
                      } else {
                            return dimName
                      }
                }
            ).entries()))
      })


      const dataSource = new DataSource();
      dataSource.records = source
      dataSource.columns = columns
      dataSource.pageInfo = dataEntity.pageInfo
      dataSource.tsConfig = dataEntity.tsConfig

      return dataSource
}


export function fetchEntityData(
    metaEntity: EntityType,
    filter: string,
    queryColumns: string[],
    success: (data: EntityDataTypes) => void,
    failed: (err: Error) => void
) {
      entityApi.fetchEntityData(
          metaEntity,
          queryColumns,
          filter,
      )?.then(resp => {
            if (resp && resp.data) {
                  success(resp.data as EntityDataTypes)
            }
      }).catch(err => {
            console.error(err)
            failed(err)
      })
}

export function fetchEntityDataWithRelationship(
    relationships: RelationType[],
    metaEntity: EntityType,
    filter: string,
    success: (data: EntityDataTypes) => void,
    failed: (err: Error) => void
) {
      let queryColumns = queryEntityColumns(relationships, metaEntity)
      entityApi.fetchEntityData(
          metaEntity,
          queryColumns,
          filter,
      )?.then(resp => {
            if (resp && resp.data) {
                  success(resp.data as EntityDataTypes)
            }
      }).catch(err => {
            console.error(err)
            failed(err)
      })
}

export function fetchConnectionData(
    relationships: RelationType[],
    metaEntity: EntityType,
    cursor: string,
    pageSize: number,
    isNextPage: boolean,
    filter: string,
    success: (data: EntityConnectionDataTypes) => void,
    failed: (err: Error) => void
) {
      let queryColumns = queryEntityColumns(relationships, metaEntity)
      entityApi.fetchConnectionEntityData(
          metaEntity,
          cursor,
          pageSize,
          isNextPage,
          filter,
          queryColumns
      )?.then(resp => {
            if (resp && resp.data) {
                  // xxxConnection
                  const json = resp.data[`${metaEntity.name}Connection`]
                  success(json as EntityConnectionDataTypes)
            }
      }).catch(err => {
            console.error(err)
            failed(err)
      })
}

export function fetchConnectionDataWithOffset(
    relationships: RelationType[],
    metaEntity: EntityType,
    current: number,
    pageSize: number,
    offset: number,
    filter: string,
    success: (data: EntityConnectionDataTypes) => void,
    failed: (err: Error) => void
) {
      let queryColumns = queryEntityColumns(relationships, metaEntity)
      if (queryColumns.length > 0) {
            entityApi.fetchConnectionEntityDataWithOffset(
                metaEntity,
                current,
                pageSize,
                offset,
                filter,
                queryColumns
            )?.then(resp => {
                  if (resp && resp.data) {
                        // xxxConnection
                        const json = resp.data[`${metaEntity.name}Connection`]
                        success(json as EntityConnectionDataTypes)
                  }
            }).catch(err => {
                  console.error(err)
                  failed(err)
            })
      } else {
            success({
                  edges: [],
                  pageInfo: new PageInfo(pageSize),
                  _aggregate: {total_count: 0},
                  tsConfig: {rowDimensionNumber: 0}
            })
      }

}


const initPaginationState = (pageSize: number) => {
      return {
            position: ['bottomRight'],
            current: 1,
            defaultCurrent: 1,
            total: 0,
            pageSize: pageSize,
            showSizeChanger: true,
            onHandleChange: (pagination: TablePaginationConfig,
                             filters: Record<string, FilterValue>,
                             sorter: SorterResult<any>) => {
            },
            pageInfo: new PageInfo(pageSize, false, '-1', true, '-1', 0, 0)

      }
}


const isLoading = (dispatch: any, loading: boolean) => {

      dispatch({
            type: ACTION_TYPE.SETTING_LOADING,
            payload: loading
      })

}


const settingTimeSeries = (dispatch: any, ts: boolean) => {

      dispatch({
            type: ACTION_TYPE.SETTING_TIMESERIES,
            payload: ts
      })

}


const settingDataSource = (dispatch: any, ds: DataSource) => {
      dispatch({
            type: ACTION_TYPE.SETTING_DATASOURCE,
            payload: ds
      })

      dispatch({
            type: ACTION_TYPE.SETTING_LOADING,
            payload: false
      })

      dispatch({
            type: ACTION_TYPE.SETTING_IS_POP_BREAD_CRUMB_ITEM,
            payload: false
      })

      dispatch({
            type: ACTION_TYPE.SETTING_IS_PUSH_BREAD_CRUMB_ITEM,
            payload: false
      })
}

const settingPagination = (dispatch: any, pag: any) => {
      dispatch({
            type: ACTION_TYPE.SETTING_PAGINATION,
            payload: pag
      })
}

const settingChangePagination = (dispatch: any, pag: any) => {
      dispatch({
            type: ACTION_TYPE.SETTING_CHANGE_PAGINATION,
            payload: pag
      })
}

const HandlerError = (dispatch: any, err: any) => {
      settingEntityDataWriteable(dispatch, false, false)
      dispatch({
            type: ACTION_TYPE.SETTING_LOADING,
            payload: false
      })

      dispatch({
            type: ACTION_TYPE.SETTING_TIMESERIES,
            payload: false
      })

      if (err) {
            let errMsg = err
            if (err && err instanceof Object
                && err.hasOwnProperty('errors')
                && err.errors instanceof Array
                && err.errors.length > 0) {
                  errMsg = err.errors[0].message
            } else if (err && err instanceof Object
                && err.hasOwnProperty("message")) {
                  errMsg = err.message
            }
            notification.error({
                  message: errMsg,
                  description: ``
            });

      }


}

const settingOffsetMappingPageGroup = (dispatch: any, mapping: any) => {
      dispatch({
            type: ACTION_TYPE.SETTING_TS_OFFSET_MAPPING_PAGE_GROUP,
            payload: mapping
      })
}

const settingEstimatePageData = (dispatch: any, mapping: any) => {
      dispatch({
            type: ACTION_TYPE.SETTING_TS_ESTIMATE_PAGE_DATA,
            payload: mapping
      })
}

const settingEntityDataWriteable = (dispatch:any, createWriteable:boolean, uploadAble:boolean) => {
      dispatch({
            type: ACTION_TYPE.SETTING_CREATE_ENTITY_DATA_WRITEABLE,
            payload: createWriteable
      })

      dispatch({
            type: ACTION_TYPE.SETTING_UPLOAD_ENTITY_DATA_WRITEABLE,
            payload: uploadAble
      })
}

const HandlerPagination = (dispatch: any, pagination: any, change: boolean) => {
      settingPagination(dispatch, pagination)
      settingChangePagination(dispatch, change)
}

const fetchConnectDataFucByLimitOffset = (dispatch: any, tableState: IState, offset: number, success: (data) => void) => {

      fetchConnectionDataWithOffset(
          tableState.relationships,
          tableState.metaEntity,
          tableState.pagination.current,
          tableState.pagination.pageSize,
          offset,
          tableState.filter,
          function (data) {
                success(data)
          }, function (err) {
                HandlerError(dispatch, undefined)
                success({
                      edges: [],
                      pageInfo: new PageInfo(tableState.pagination.pageSize),
                      _aggregate: {total_count: 0},
                      tsConfig: {rowDimensionNumber: 0}
                })

          })

}

const fetchConnectDataFucByCursor = (dispatch: any, tableState: IState, success: (data) => void) => {

      /**
       cursor: {
              startCursor: '-1',
              endCursor: '-1',
              hasNextPage: true,
              hasPreviousPage: false
            }

       */
      fetchConnectionData(
          tableState.relationships,
          tableState.metaEntity,
          tableState.pagination.pageInfo.hasNextPage ? tableState.pagination.pageInfo.endCursor : tableState.pagination.pageInfo.startCursor,
          tableState.pagination.pageSize,
          tableState.pagination.pageInfo.hasNextPage,
          tableState.filter,
          function (data) {
                success(data)
          }, function (err) {
                HandlerError(dispatch, undefined)
                success({
                      edges: [],
                      pageInfo: new PageInfo(tableState.pagination.pageSize),
                })
          })

}


const useStore = {
      initEntity: (dispatch: any, initEntity: string, initFilter: string, initPageSize: number) => {

            dispatch({
                  type: ACTION_TYPE.SETTING_ENTITY,
                  payload: initEntity
            })
            dispatch({
                  type: ACTION_TYPE.SETTING_FILTER,
                  payload: initFilter
            })
            dispatch({
                  type: ACTION_TYPE.SETTING_PAGINATION,
                  payload: initPaginationState(initPageSize)
            })
            dispatch({
                  type: ACTION_TYPE.SETTING_ROW_SELECTED_DATA,
                  payload: {selectedRowKeys:[], selectedRows:[]}
            })


            // 初始化table时,要清空面包屑
            let breadCrumbItems = []
            breadCrumbItems.push(
                new BCItem(Guid.create().toString(),
                    undefined,
                    undefined,
                    initEntity,
                    undefined))

            dispatch({
                  type: ACTION_TYPE.SETTING_BREADCRUMB_ITEMS,
                  payload: breadCrumbItems
            })

      },
      loadingRelationship: (dispatch: any, namespace: string) => {
            relationApi.list(namespace).then(resp => {
                  dispatch({
                        type: ACTION_TYPE.SETTING_RELATIONSHIPS,
                        payload: resp?.data
                  })
            }).catch(err => {
                  HandlerError(dispatch, undefined)
            })
      },
      loadingMetaDataSource: (dispatch: any, tableState: IState, data_source_id: number) => {
            if (!tableState.metaDataSources.has(tableState.metaEntity.mapping.data_source_id)) {
                  dataSourceApi.getDataSource(data_source_id).then(resp => {
                        dispatch({
                              type: ACTION_TYPE.SETTING_NAMESPACE_DATASOURCES,
                              payload: resp?.data as DataSourceType
                        })
                  }).catch(err => {
                        HandlerError(dispatch, undefined)
                  })
            } else {
                  dispatch({
                        type: ACTION_TYPE.SETTING_NAMESPACE_DATASOURCES,
                        payload: tableState.metaDataSources
                  })
            }

      },
      loadingDetailEntity: (dispatch: any, namespace: string, entity: string) => {
            entityApi.detail(namespace, entity).then(resp => {
                  dispatch({
                        type: ACTION_TYPE.SETTING_METAENTITY,
                        payload: resp?.data
                  })
            }).catch(err => {
                  HandlerError(dispatch, undefined)
            })
      },
      loadingConnectionData: (dispatch: any, tableState: IState) => {
            fetchConnectDataFucByLimitOffset(dispatch, tableState, -1, (data) => {
                  const dataOfEntity = {edges: data.edges, pageInfo: data.pageInfo}
                  const ds = BuildConnectionDataSource(tableState.relationships, tableState.metaEntity, dataOfEntity)
                  tableState.pagination.total = ds.pageInfo?.total
                  settingDataSource(dispatch, ds)
                  HandlerPagination(dispatch, tableState.pagination, false)
            })
      },
      loadingK2BoxConnectionData: (dispatch: any, tableState: IState) => {

            // 首页 / 上一页 / 下一页 进行切换
            tableState.pagination.pageInfo = tableState.cursors.get(tableState.pagination.current - 1)

            fetchConnectDataFucByCursor(dispatch, tableState, (data) => {
                  const dataOfEntity = {edges: data.edges, pageInfo: data.pageInfo, _aggregate: data._aggregate}
                  const ds = BuildConnectionDataSource(tableState.relationships, tableState.metaEntity, dataOfEntity)

                  if (ds?.pageInfo.hasNextPage) {
                        tableState.pagination.total = (tableState.pagination.current + 1) * tableState.pagination.pageSize
                  }
                  const currentPageInfo = new PageInfo(tableState.pagination.pageSize,
                      ds.pageInfo?.hasPreviousPage, ds.pageInfo?.startCursor,
                      ds.pageInfo?.hasNextPage, ds.pageInfo?.endCursor,
                      tableState.pagination.total,
                      tableState.pagination.current
                  )
                  tableState.pagination.pageInfo = currentPageInfo

                  tableState.cursors.set(currentPageInfo.current, currentPageInfo)

                  settingDataSource(dispatch, ds)
                  HandlerPagination(dispatch, tableState.pagination, false)


            })

      },
      loadingTSConnectionData: (dispatch: any, tableState: IState, offset: number) => {
            fetchConnectDataFucByLimitOffset(dispatch, tableState, offset, (data) => {
                  const dataOfEntity = {edges: data.edges, pageInfo: data.pageInfo, tsConfig: data.tsConfig}
                  const ds = BuildConnectionDataSource(tableState.relationships, tableState.metaEntity, dataOfEntity)
                  //不是常规分页(按照行级的维度个数分页策略)
                  if (ds.records.length > 0
                      && ds.records.length > tableState.pagination.pageSize
                      && ds.tsConfig?.rowDimensionNumber > 1
                  ) {
                        // 计算这波数据页数
                        let pages = Math.ceil(ds.pageInfo?.total / tableState.pagination.pageSize)
                        // 按照这波数据的维度个数，对分页进行分组
                        let pageGroupLength = Math.ceil(pages / ds.tsConfig?.rowDimensionNumber)
                        // {0: [1,2], 1: [3,4], 2: [5, 6], 3: [7, 8]}
                        let offsetMappingPageGroup = new Map<number, Array>()
                        for (let i = 0; i < pageGroupLength; i++) {
                              for (let j = 0; j < pages; j++) {
                                    // 0, 1, 2, 3
                                    // 4, 5, 6, 7
                                    if (Math.floor(j / ds.tsConfig?.rowDimensionNumber) === i) {
                                          if (offsetMappingPageGroup.has(i)) {
                                                offsetMappingPageGroup.get(i).push(j + 1)
                                          } else {
                                                let arr = new Array<number>()
                                                arr.push(j + 1)
                                                offsetMappingPageGroup.set(i, arr)
                                          }
                                    }
                              }
                        }

                        //预加载分页数据
                        let currentMappingPages = undefined
                        for (let [key, value] of offsetMappingPageGroup) {
                              if (value.includes(tableState.pagination.current)) {
                                    currentMappingPages = value
                                    break
                              }
                        }

                        if (currentMappingPages && currentMappingPages.length > 0) {
                              // {1: ds, 2: ds, 3: ds}
                              let tsEstimatePageData = new Map<number, any>()
                              for (let i = 0; i < currentMappingPages.length; i++) {
                                    let pageNum = currentMappingPages[i]
                                    let start = i * tableState.pagination.pageSize
                                    let end = start + tableState.pagination.pageSize
                                    let pageData = ds.records.slice(start, end)
                                    let newDataSource = new DataSource()
                                    newDataSource.columns = ds.columns
                                    newDataSource.records = pageData
                                    newDataSource.pageInfo = ds.pageInfo
                                    newDataSource.tsConfig = ds.tsConfig
                                    tsEstimatePageData.set(pageNum, newDataSource)
                              }

                              if (tsEstimatePageData.size > 0 && tsEstimatePageData.has(tableState.pagination.current)) {
                                    settingEstimatePageData(dispatch, tsEstimatePageData)
                                    settingOffsetMappingPageGroup(dispatch, offsetMappingPageGroup)
                                    settingDataSource(dispatch, tsEstimatePageData.get(tableState.pagination.current))
                              }

                        }

                  } else {
                        // 常规分页
                        settingDataSource(dispatch, ds)
                  }

                  tableState.pagination.total = ds.pageInfo?.total
                  HandlerPagination(dispatch, tableState.pagination, false)

            })

      }


}

const initialTableViewState = (entity: string, filter: string, pageSize: number): IState => {
      // const ds = new DataSource()
      // ds.columns = []
      // ds.records = []

      const pag = initPaginationState(pageSize)
      const cursors = new Map<number, IPageInfo>()
      cursors.set(0, pag.pageInfo)

      return {
            filter: filter,
            entity: entity,
            metaEntity: undefined,
            relationships: undefined,
            metaDataSources: new Map<number, DataSourceType>(),
            createEntityDataWriteable:false,
            uploadEntityDataWriteable:false,
            modifyEntityRecord:undefined,
            resetModifyEntityRecord: ()=>{},
            dataSource: new DataSource(),
            tsEstimatePageData: undefined, // 时序类型的预估分页数据
            offsetMappingPageGroup: new Map<number, Array>(),
            loading: true,
            pagination: initPaginationState(pageSize),
            cursors: cursors,
            changePagination: false,
            timeSeries: false,
            breadCrumbItems: new Array<BCItem>(),
            onRefreshTableView: () => {
            },
            popBreadCrumbItem: (item: BCItem) => {
            },
            pushBreadCrumbItem: (col: string, record: any) => {
            },
            modifyRecord:(r:IObject) =>{},
            isPopBreadCrumbItem: false,
            isPushBreadCrumbItem: false,
            drawerVisible:false,
            drawerVisibleFunc:(visible:boolean) => {},
            rowSelection:{selectedRowKeys:[], selectedRows:[]},
            onRowSelectionChange:({selectedRowKeys:[], selectedRows:[]}) => {},
            onDeleteSelectedRows:() => {}
      }
}


export const useTableViewState = (namespace: string, initEntity: string, initFilter: string, initPageSize: number) => {
      const [tableState, dispatch] = useReducer(tableViewReducer, initialTableViewState(initEntity, initFilter, initPageSize))

      tableState.onRowSelectionChange = (selectionData:IObject) =>{

            dispatch({
                  type: ACTION_TYPE.SETTING_ROW_SELECTED_DATA,
                  payload: selectionData
            })
      }
      // 改变搜索抽屉的可视状态
      tableState.drawerVisibleFunc = (visible:boolean) =>{
            dispatch({
                  type: ACTION_TYPE.SETTING_DRAWER_VISIBLE,
                  payload: visible
            })
      }

      // change page
      tableState.pagination.onHandleChange = (pagination: TablePaginationConfig,
                                              filters: Record<string, FilterValue>,
                                              sorter: SorterResult<any>) => {

            isLoading(dispatch, true)
            if (tableState.pagination.pageSize !== pagination.pageSize) {
                  const dataSourceType = tableState.metaDataSources.get(tableState.metaEntity.mapping.data_source_id)
                  // 实体映射的数据源类型为 k2boxng时，切换pageSize时，从首页开始加载数据
                  if (dataSourceType.type === META_ENTITY_BIND_DATASOURCE.K2BOXNG || dataSourceType.type === META_ENTITY_BIND_DATASOURCE.K2BOX) {
                        pagination.current = 1
                  }
            }

            HandlerPagination(dispatch, pagination, true)

      }

      tableState.modifyRecord = (record:IObject) =>{
            /**
             *

             {
                    "key": 3,
                    "description": null,
                    "updated_info": null,
                    "updated_user": "admin",
                    "display_name": "1#水轮发电机",
                    "name": "PE0000000001",
                    "id": 5,
                    "created_at": "2021-11-02T15:44:49.127434Z",
                    "updated_at": "2021-11-02T15:44:49.127434Z",
                    "asset_types": {
                      "name": "asset_types",
                      "displayName": "对象",
                      "mappingCount": 1,
                      "dimensionRecord": {
                        "id": 8
                      }
                    },
                    "created_user": "admin",
                    "comp_parent_id": 4,
                    "asset_type_id": 8
             }

             {
              "key": 1,
              "created_user": "admin",
              "updated_info": null,
              "updated_at": "2021-11-02T10:24:17.388529Z",
              "description": null,
              "id": 3,
              "asset_instances": {
                "name": "asset_instances",
                "displayName": "列表",
                "mappingCount": 3
              },
              "name": "DFEM_Station",
              "display_name": "电站",
              "created_at": "2021-11-02T10:24:17.388529Z",
              "inheritance_parent_id": 1,
              "updated_user": "admin"
            }

             */

            //todo: iotdb类型暂不修改
            let annotationOfResource = queryResourceAnnotation(tableState.metaEntity)
            if (annotationOfResource) {
                  if (annotationOfResource.params[0].Value !== "OBJECT_STORAGE") {
                        return
                  }
            }

            // 新建的同时，支持更新记录
            if (tableState.createEntityDataWriteable) {
                  dispatch({
                        type: ACTION_TYPE.SETTING_MODIFY_ENTITY_RECORD,
                        payload: record
                  })
            }

      }

      // reset modify entity record
      tableState.resetModifyEntityRecord = () => {
            dispatch({
                  type: ACTION_TYPE.SETTING_MODIFY_ENTITY_RECORD,
                  payload: undefined
            })
      }

      tableState.onDeleteSelectedRows = () =>{
            const identityExp = generationBatchedDeleteIdentityExpression(tableState.metaEntity,tableState.rowSelection.selectedRows)
            let queryColumns:string[] = []
            for (let pkAttr of tableState.metaEntity.identity_type.attributes) {
                  queryColumns.push(pkAttr.name)
            }
            isLoading(dispatch, true)
            entityApi.fetchDeleteEntityDataTypeRecord(tableState.metaEntity,identityExp, queryColumns)
                .then(resp => {
                      dispatch({
                            type:ACTION_TYPE.SETTING_ROW_SELECTED_DATA,
                            payload:{selectedRowKeys:[], selectedRows:[]}
                      })
                      tableState.onRefreshTableView()
                }).catch(err => {
                  console.log("delete record error =>", err)
                  isLoading(dispatch, false)
            })
      }

      // refresh table
      tableState.onRefreshTableView = () => {
            isLoading(dispatch, true)
            HandlerPagination(dispatch, tableState.pagination, true)
      }

      // remove bread crumb item
      tableState.popBreadCrumbItem = (item: BCItem) => {
            //update entity and  filter  status
            let removeItemIndex = -1;
            for (let i = 0; i < tableState.breadCrumbItems.length; i++) {
                  if (item.itemId === tableState.breadCrumbItems[i].itemId) {
                        removeItemIndex = i + 1
                        break
                  }
            }

            dispatch({
                  type: ACTION_TYPE.SETTING_BREADCRUMB_ITEMS,
                  payload: tableState.breadCrumbItems.slice(0, removeItemIndex)
            })

            dispatch({
                  type: ACTION_TYPE.SETTING_PAGINATION,
                  payload: initPaginationState(initPageSize)
            })

            dispatch({
                  type: ACTION_TYPE.SETTING_FILTER,
                  payload: item.queryFilter
            })
            if (item.dimensionMetaEntity && typeof item.metaEntity === 'object') {
                  dispatch({
                        type: ACTION_TYPE.SETTING_ENTITY,
                        payload: item.dimensionMetaEntity.name
                  })
            } else {
                  dispatch({
                        type: ACTION_TYPE.SETTING_ENTITY,
                        payload: item.metaEntity
                  })

            }

      }

      // add bread crumb item
      tableState.pushBreadCrumbItem = (col: string, record: any) => {
            const relationshipMetaEntity = matchRelationshipEntity(tableState.relationships, tableState.metaEntity, col);
            const relationship = matchRelationship(tableState.relationships, tableState.metaEntity, col);
            const queryEntity = relationshipMetaEntity?.name

            const queryPrimariesKVFun = (metaEntity: EntityType, record: any) => {
                  let primariesKV: string[] = []
                  if (metaEntity && metaEntity.attributes) {
                        for (let attr of metaEntity.attributes) {
                              if (attr && attr.is_primary && attr.name) {
                                    if (attr.type === 'int' || attr.type === 'float' || attr.type === 'bool') {
                                          primariesKV.push(`${attr.name}: ${record[attr.name]} `)
                                    } else {
                                          primariesKV.push(`${attr.name}: "${record[attr.name]}" `)
                                    }
                              }
                        }
                  }
                  return primariesKV
            }

            let primariesKV = queryPrimariesKVFun(tableState.metaEntity, record)

            let queryFilter = `${tableState.metaEntity.name}:{${primariesKV.join(',')}}`
            if (relationship.object_type_name1 === relationshipMetaEntity?.name) {
                  queryFilter = `${relationship.attribute_name1}:{${primariesKV.join(',')}}`
            }
            if (relationship.object_type_name2 === relationshipMetaEntity?.name) {
                  queryFilter = `${relationship.attribute_name2}:{${primariesKV.join(',')}}`
            }

            // 时序数据跳转到关系型数据filter构建
            //tableState.metaEntity.annotations
            //                 && tableState.metaEntity.annotations.length > 0
            const mappingDataSource = tableState.metaDataSources.get(tableState.metaEntity.mapping.data_source_id)
            if (mappingDataSource.type === META_ENTITY_BIND_DATASOURCE.IOTDB) {
                  let primariesKV: string[]
                  if (relationship.object_type_name1 === relationshipMetaEntity?.name) {
                        primariesKV = queryPrimariesKVFun(relationshipMetaEntity, record[relationship.attribute_name2]["dimensionRecord"])
                  }
                  if (relationship.object_type_name2 === relationshipMetaEntity?.name) {
                        primariesKV = queryPrimariesKVFun(relationshipMetaEntity, record[relationship.attribute_name1]["dimensionRecord"])
                  }
                  queryFilter = `${primariesKV.join(',')}`
                  // 清空时序数据模型中预加载分页数据
                  // settingOffsetMappingPageGroup(dispatch, new Map<number, Array>())
                  // settingEstimatePageData(dispatch, new Map<number, any>())
            }

            if (queryEntity && queryFilter && relationship) {
                  tableState.breadCrumbItems.push(
                      new BCItem(Guid.create().toString(),
                          record, queryFilter, tableState.metaEntity, relationshipMetaEntity))

                  dispatch({
                        type: ACTION_TYPE.SETTING_BREADCRUMB_ITEMS,
                        payload: tableState.breadCrumbItems
                  })

                  dispatch({
                        type: ACTION_TYPE.SETTING_PAGINATION,
                        payload: initPaginationState(initPageSize)
                  })

                  dispatch({
                        type: ACTION_TYPE.SETTING_FILTER,
                        payload: queryFilter
                  })

                  dispatch({
                        type: ACTION_TYPE.SETTING_ENTITY,
                        payload: queryEntity
                  })
            }

      }

      // single only loading relationships
      useEffect(() => {
            useStore.loadingRelationship(dispatch, namespace)
      }, [])


      // init entity
      useEffect(() => {
            if (initEntity) {
                  useStore.initEntity(dispatch, initEntity, initFilter, initPageSize)
            }

      }, [initEntity, initFilter])

      // loading metaEntity by state entity and filter
      useEffect(() => {
            isLoading(dispatch, true)
            useStore.loadingDetailEntity(dispatch, namespace, tableState.entity)
      }, [tableState.entity, tableState.filter])


      // loading metaEntity by state isPopBreadCrumbItem
      useEffect(() => {
            if (tableState.isPopBreadCrumbItem) {
                  isLoading(dispatch, true)
                  // loading entity
                  useStore.loadingDetailEntity(dispatch, namespace, tableState.entity)
            }
      }, [tableState.isPopBreadCrumbItem])

      useEffect(() => {
            if (tableState.metaEntity) {
                  if (tableState.metaEntity.mapping) {
                        useStore.loadingMetaDataSource(dispatch, tableState, tableState.metaEntity.mapping.data_source_id)
                  } else {
                        settingDataSource(dispatch, BuildConnectionDataSource(tableState.relationships, tableState.metaEntity, {edges: []}))
                        if (tableState.metaEntity.state !== 'PUBLISH_DONE') {
                              message.warning(`实体未发布或发布中...`);
                        }
                        settingEntityDataWriteable(dispatch, false, false)
                  }

            }

      }, [tableState.metaEntity])


      // loading data of entity ?
      useEffect(() => {
            if ((tableState.metaEntity
                    && tableState.relationships
                    && tableState.metaDataSources.size > 0
                    && tableState.metaEntity.mapping
                    && tableState.metaDataSources.has(tableState.metaEntity.mapping.data_source_id))
                || tableState.changePagination) {

                  const bindDataSource = tableState.metaDataSources.get(tableState.metaEntity.mapping.data_source_id)

                  let annotationOfResource = queryResourceAnnotation(tableState.metaEntity)
                  // if (tableState.metaEntity.annotations && tableState.metaEntity.annotations.length > 0) {
                  //       for (let ann of tableState.metaEntity.annotations) {
                  //             if (ann.annotation_type_name === 'RESOURCE_TYPE') {
                  //                   annotationOfResource = ann
                  //                   break
                  //             }
                  //       }
                  // }

                  if (tableState.breadCrumbItems.length > 1) {
                        settingEntityDataWriteable(dispatch, false, false)
                  }else if (annotationOfResource && annotationOfResource.params[0].Value === RESOURCE_TYPE.OBJECT_STORAGE) {
                        settingEntityDataWriteable(dispatch, bindDataSource.data_writeable, false)
                  }else{
                        settingEntityDataWriteable(dispatch, bindDataSource.data_writeable, bindDataSource.data_writeable)
                  }

                  if (bindDataSource.type === META_ENTITY_BIND_DATASOURCE.IOTDB) {
                        // 初始加载分页数据
                        if (!tableState.tsEstimatePageData || tableState.tsEstimatePageData.size === 0) {
                              let offset = 0
                              if (tableState.pagination.current > 1) {
                                    offset = (tableState.pagination.current - 1) * tableState.pagination.pageSize
                              }

                              useStore.loadingTSConnectionData(dispatch, tableState, offset)

                              // 未找到预分页数据，加载新分页数据
                        } else {
                              let currentPageMappingOffset = undefined
                              for (let [key, value] of tableState.offsetMappingPageGroup) {
                                    if (value.includes(tableState.pagination.current)) {
                                          currentPageMappingOffset = key
                                          break
                                    }
                              }
                              // 切换分页
                              if (currentPageMappingOffset >= 0) {
                                    let offset: number = currentPageMappingOffset * tableState.pagination.pageSize
                                    useStore.loadingTSConnectionData(dispatch, tableState, offset)
                              } else {
                                    HandlerError(dispatch, `分页切换失败: 页码${tableState.pagination.current}对应offset不存在`)
                              }
                        }

                  }
                  else if (bindDataSource.type === META_ENTITY_BIND_DATASOURCE.K2BOXNG
                      || bindDataSource.type === META_ENTITY_BIND_DATASOURCE.K2BOX) {
                        useStore.loadingK2BoxConnectionData(dispatch, tableState)
                  } else {
                        useStore.loadingConnectionData(dispatch, tableState)
                  }

            }
      }, [tableState.metaEntity, tableState.relationships, tableState.metaDataSources.size, tableState.changePagination])


      return tableState

}
