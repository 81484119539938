import {ACTION_TYPE, IState} from "./typings";
import {message, UploadProps} from "antd";
import {useEffect, useReducer} from "react";
import {uploadReducer} from "./reducer";
import entityApi from "../../api/requests/entityReqs";
import {EntityType} from "../../api/types/EntityTypes";
import {ANNOTATION_TYPE, RESOURCE_TYPE} from "../../api/types/CommonTypes";

function uploadProps(f: (v: FormData) => void): UploadProps {

      return {
            showUploadList: false,
            accept: '.csv',
            beforeUpload: (file, fileList) => {
                  const reader = new FileReader();
                  reader.readAsText(file);
                  reader.onload = (e) => {
                        if (e?.target) {
                              let formData: FormData = new FormData();
                              formData.append('file', fileList[0]);
                              f(formData)
                        }
                  }
                  return false;
            },
            // onChange:(info) =>{
            // }
      }
}


const initialState = (uploadDisable: boolean): IState => {

      const initState = {
            formData: undefined,
            isLoading: false,
            disabled: uploadDisable,
            uploadValidate: false,
            uploadComponentProps: {},
            resource_type: undefined
      }

      return initState
}

export const useUploadState = (namespace: string, metaEntity: EntityType, disabled: boolean, onRefreshTableView: (r: boolean) => void) => {
      const [uploadState, dispatch] = useReducer(uploadReducer, initialState(disabled))

      // useEffect(() => {
      //       if (metaEntity) {
      //             if (metaEntity.annotations && metaEntity.annotations.length > 0) {
      //                   let annotation = undefined
      //                   for (let ann of metaEntity.annotations) {
      //                         if (ann.annotation_type_name === ANNOTATION_TYPE.RESOURCE_TYPE) {
      //                               annotation = ann
      //                               break
      //                         }
      //                   }
      //
      //                   if (annotation && annotation.params[0].Value === RESOURCE_TYPE.OBJECT_STORAGE) {
      //                         dispatch({
      //                               type: ACTION_TYPE.SETTING_RESOURCE_TYPE,
      //                               payload: RESOURCE_TYPE.OBJECT_STORAGE
      //                         })
      //                   }
      //             }else{
      //                   dispatch({
      //                         type: ACTION_TYPE.SETTING_UPLOAD_BUTTON_DISABLE,
      //                         payload: disabled
      //                   })
      //             }
      //       }
      //
      // }, [metaEntity])


      const settingFormData = (formData: FormData) => {
            if (formData) {
                  dispatch({
                        type: ACTION_TYPE.SETTING_FORM_DATA,
                        payload: formData
                  })
            }
      }

      useEffect(() => {
            if (uploadState.formData) {

                  dispatch({
                        type: ACTION_TYPE.SETTING_LOADING,
                        payload: true
                  })

                  dispatch({
                        type: ACTION_TYPE.UPLOAD_VALIDATE,
                        payload: true
                  })


                  entityApi.importEntityDataFromCSV(namespace, metaEntity.name, uploadState.formData)
                      .then((resp) => {
                            message.success('上传成功...');
                            onRefreshTableView(true)
                      })
                      .catch((err) => {
                            console.error(err)
                      })
                      .finally(() => {

                            dispatch({
                                  type: ACTION_TYPE.SETTING_LOADING,
                                  payload: false
                            })

                            dispatch({
                                  type: ACTION_TYPE.SETTING_FORM_DATA,
                                  payload: undefined
                            })

                            dispatch({
                                  type: ACTION_TYPE.UPLOAD_VALIDATE,
                                  payload: false
                            })

                      })


            }

      }, [uploadState.formData])

      uploadState.uploadComponentProps = uploadProps(settingFormData)

      return uploadState
}

