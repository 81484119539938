import EntityDataTableView from "./EntityDataTableView";

// @ts-ignore
EntityDataTableView.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template',
    },
    {
      name: 'entity',
      type: 'template',
    },
    {
      name: 'filter',
      type: 'template',
    },
    {
      name: 'editable',
      type: 'boolean',
    },
    {
      name: 'pageSize',
      type: 'number',
    },
    {
      name: 'onItemEditable',
      type: 'boolean',
    },
    {
      name: 'pageSize',
      type: 'number',
    },
    {
      name: 'newCreateView',
      type: 'boolean',
    },
    {
      name: 'uploadView',
      type: 'boolean',
    },
    {
      name: 'deleteView',
      type: 'boolean',
    },
    {
      name: 'searchView',
      type: 'boolean',
    },
    {
      name: 'breadCrumbView',
      type: 'boolean',
    },
    {
      name: 'onSearchHandle',
      type: 'template',
    },
  ],
  styles: ['width', 'height'],
  editorConfig: {
    defaultValue: {
      disabled: false
    }
  }
}


export default EntityDataTableView;
