import {Day4CustomFC} from "../../types";
import {useState} from "react";
import {Button, Form, Input, message, Modal, Select, Tag} from "antd";
import {useMutation} from "react-query";
import useMyQuery from "../../api/useMyQuery";

import './modalBindDataSource.less'
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import { DataSourceType } from "../../api/types/DataSourceTypes";
import namespaceApi from "../../api/requests/namespaceReqs";
import { DataSourceBindInfo } from "../../api/types/NamespaceTypes";

interface ModalBindDataSourcePropType {
  namespace: string,
  showButton: boolean,
  onOk: () => void,
  onCancel: () => void
}

const {Option} = Select;

const ModalBindDataSource: Day4CustomFC<ModalBindDataSourcePropType> = ({
  namespace,
  showButton,
  onOk,
}) => {
  const [modalVisible, setModalVisible] = useState(!showButton);

  const {data: datasourceToChooseResponse} = useMyQuery(['datasources'], dataSourceApi.list);
  const datasourceToChoose = new Array<DataSourceType>()
  // 过滤掉非本地的数据源
  if (datasourceToChooseResponse?.data) {
    let data = datasourceToChooseResponse?.data;
    data.map((v) => {
      if (!v.is_local && !v.is_external) {
        if (v.category === "RDB" && v.mode != "instance_mode") {
          return
        }
        datasourceToChoose.push(v)
      }
    })
  }

  const [selectedDatasource, setSelectedDatasource] = useState<DataSourceType>();

  const onSelectChange = (id: string) => {
    datasourceToChoose.map(v => {
      if (v.id == id) {
        setSelectedDatasource(v)
        return
      }
    })
  }

  const [form] = Form.useForm();

  const mutationBindRdb =
    useMutation((bindInfo: DataSourceBindInfo) => namespaceApi.bindDataSource(bindInfo.namespace, bindInfo.data_source));

  const handleOk = async () => {
    try {
      if (!selectedDatasource) {
        message.error("请选择数据源")
        return
      }

      const values = await form.validateFields() as {data_source_id: string, database_name: string}
      if (selectedDatasource.category === "RDB" && selectedDatasource.mode === "instance_mode") { // 关系型数据库，实例模式下，新增数据源需绑定database
        if (!values.database_name) {
          message.error("实例模式下，新增私有数据源必须指定数据库名称")
          return
        }
        selectedDatasource.database_name = values.database_name
      }
      const bindInfo: DataSourceBindInfo = {
        data_source: selectedDatasource,
        namespace: namespace
      }

      console.log(bindInfo)
      const result = await mutationBindRdb.mutateAsync(bindInfo);

      if (result?.data) {
        message.success('新增成功！');

        form.resetFields();
        setSelectedDatasource(undefined)
        setModalVisible(false);

        onOk && onOk();
        return result.data;
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setSelectedDatasource(undefined)
    setModalVisible(false);
  }

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk} disabled={!namespace}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      {showButton && 
      <Button type="primary"
        onClick={() => setModalVisible(true)}
        disabled={!namespace}
      >新增</Button>
      }

      <Modal visible={modalVisible} footer={footer}
             title={"新增私有数据源"}
             closable={false} maskClosable={false}
      >
        <Form
          name="bindDataSource"
          form={form}
          labelCol={{span: 5}}
        >
          <Form.Item
            label={"数据源"}
            name="data_source_id"
            rules={[
              {required: true, message: '数据源未选择'}
            ]}
          >
            <Select
              placeholder={'选择一个非私有的数据源'}
              onChange={onSelectChange}
              dropdownMatchSelectWidth
              showSearch
              optionFilterProp={'key'}
            >
              {datasourceToChoose && datasourceToChoose.map(ds => (
                <Option value={ds.id || ""} key={`${ds.id}##${ds.name}##${ds.dsn}`}>
                  <div className={'datasource-name'}>{ds.name} <Tag>{ds.category}</Tag></div>
                  <div className={'datasource-desc'}>{ds.dsn_for_display}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedDatasource && selectedDatasource.mode === "instance_mode" &&
          <Form.Item
            label={"数据库"}
            name="database_name"
            initialValue={namespace}
            rules={[
              {required: true, message: '数据库名称'}
            ]}
          >
            <Input />
          </Form.Item>
          }
        </Form>
      </Modal>
    </>
  )
}

export default ModalBindDataSource
