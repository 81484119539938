import {ACTION_TYPE, IAction, IState} from "./typings";
import {EntityType} from "../../api/types/EntityTypes";
import {RESOURCE_TYPE} from "../../api/types/CommonTypes";

function uploadReducer(state: IState, action: IAction): IState {
  const {type, payload} = action;
  switch (type) {
    case ACTION_TYPE.SETTING_FORM_DATA:
      return {
        ...state,
        formData: payload ? payload as FormData : undefined
      }
    case ACTION_TYPE.SETTING_LOADING:
      return {
        ...state,
        isLoading: payload as boolean
      }
    case ACTION_TYPE.UPLOAD_VALIDATE:
      return {
        ...state,
        uploadValidate: payload as boolean
      }
    case ACTION_TYPE.SETTING_RESOURCE_TYPE:
      const rt = payload as RESOURCE_TYPE
      return {
        ...state,
        disabled: rt === RESOURCE_TYPE.OBJECT_STORAGE,
        resource_type: rt
      }
    case ACTION_TYPE.SETTING_UPLOAD_BUTTON_DISABLE:

      return {
        ...state,
        disabled: payload as boolean,
      }
    default:
      return state;
  }
}

export {
  uploadReducer
}
