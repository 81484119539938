//@ts-nocheck
import React from "react";
import {Breadcrumb} from "antd";
import {BCItem} from "../typings";

interface Props {
      item: BCItem
      onItemHandle: (item: BCItem) => void
}


const BreadCrumbItem: React.FC<Props> = ({
                                               item,
                                               onItemHandle
                                         }) => {

      const onHandle = (e: MouseEvent) => {
            onItemHandle(item)
      }

      return (
          <>
                <Breadcrumb.Item onClick={(e: MouseEvent) => onHandle(e)}>
                      {item.getContent()}
                </Breadcrumb.Item>
          </>
      )
}


export default BreadCrumbItem
