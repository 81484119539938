/* eslint-disable no-continue */
//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import {
      Divider,
      Modal,
      List,
      Button
} from 'antd';
import './DataForm.less';
import EntityDataTableView from "../EntityDataTableView";
import {EntityType} from "../../api/types/EntityTypes";
import DrawerFormComponent from "../EntityDataTableView/searchDrawer/DrawerForm";
import './RelationModal.less';
import {IObject} from "../EntityDataTableView/typings";
import {Key} from "antd/lib/table/interface";
import {formattedDisplay} from "../EntityDataTableView/useTableView";
import {RelationTypeType} from "../../api/types/RelationTypes";
import {generationSelectedRelationRecord} from "./useUpsertEntityData";

interface IRelationModalProps {
      metaEntity: EntityType
      relationEntity: EntityType
      relationships:RelationTypeType[],
      accessAttribute:string,
      modalVisible: boolean
      lastedSelectedItems:SelectedItem[]
      modalVisibleCallback: (v: boolean) => void
      refillingNewSelectedItems: (v:SelectedItem[]) => void
}


export interface SelectedItem {
      key: string
      title: string
      content: string
      row:IObject
}

const RelationModal: React.FC<IRelationModalProps> = props => {
      const {
            metaEntity,
            relationEntity,
            relationships,
            accessAttribute,
            modalVisible = false,
            lastedSelectedItems = [],
            modalVisibleCallback = (v: boolean) => {
            },
            refillingNewSelectedItems = (v:SelectedItem[]) => {}
      } = props;

      /**
       * namespace,
       entity,
       filter,
       editable = true,
       pageSize = 10,
       newCreateView = true,
       uploadView = true,
       deleteView = true,
       searchView = true,
       breadCrumbView = true,
       */
      const tableViewRef = useRef(null)
      const [isModalVisible, setIsModalVisible] = useState(modalVisible)
      const [drawerVisible, setDrawerVisible] = useState(false)
      const [rowSelectionType, setRowSelectionType] = useState('checkbox')
      const [moveToSelectedDisabled, setMoveToSelectedDisabled] = useState(true)
      const [selectedRecords, setSelectedRecords] = useState([])
      const [checkSelectedRows, setCheckSelectedRows] = useState({selectedRowKeys: [], selectedRows: []})

      useEffect(() =>{
            const fd = formattedDisplay(accessAttribute, metaEntity, relationships)
            if (fd === '列表'){
                  setRowSelectionType('checkbox')
            }
            if (fd === '对象') {
                  setRowSelectionType('radio')
            }
      }, [])

      useEffect(() =>{
            const keys = lastedSelectedItems.map(i => i.key)
            const rows = lastedSelectedItems.map(i => i.row)
            // 修改子组件的keys 和 rows
            tableViewRef.current.onHandleRowSelection(keys, rows)
            // 设置当前组件中keys和rows
            setCheckSelectedRows({selectedRowKeys: keys, selectedRows: rows})
            setSelectedRecords(lastedSelectedItems)

      },lastedSelectedItems)

      useEffect(() => {
            setIsModalVisible(modalVisible)
      }, [modalVisible])

      useEffect(() => {
            if (checkSelectedRows.selectedRowKeys.length > 0) {
                  setMoveToSelectedDisabled(false)
            }else{
                  setMoveToSelectedDisabled(true)
            }

      }, [selectedRecords, checkSelectedRows])


      const handleCancel = () => {
            setIsModalVisible(false)
            modalVisibleCallback(false)

            // 清空记录
            setCheckSelectedRows({selectedRowKeys: [], selectedRows: []})
            setSelectedRecords([])
      }

      const handleOk = () => {
            setIsModalVisible(false)
            modalVisibleCallback(false)
            // 回填最新的选中记录
            refillingNewSelectedItems(selectedRecords)

      }

      const removeSelectItem = (item) => {

            setSelectedRecords(selectedRecords.filter((s: SelectedItem) => s.key !== item.key))
            const keys = checkSelectedRows.selectedRowKeys.filter((s:string) => s !== item.key)
            const rows = checkSelectedRows.selectedRows.filter((row:IObject) => row.key  !== item.key)
            setCheckSelectedRows({selectedRowKeys: keys, selectedRows: rows})
            tableViewRef.current.onHandleRowSelection(keys, rows)

      }


      // 响应选择的记录
      const onMoveToSelected = () => {
            if (checkSelectedRows.selectedRows.length > 0) {
                  let items: SelectedItem[] = []
                  for (let i = 0; i < checkSelectedRows.selectedRows.length; i++) {
                        let record = checkSelectedRows.selectedRows[i]
                        // generationSelectedRelationRecord(relationEntity, record.key, record)
                        // let title = []
                        // let content = []
                        // for (let pkAttribute of relationEntity.identity_type.attributes) {
                        //       title.push(pkAttribute.name)
                        //       content.push(JSON.stringify({[pkAttribute.name]: record[pkAttribute.name]}))
                        // }
                        // items.push({key: key, title: title.join(','), content: content.join(","), row: record})
                        items.push(generationSelectedRelationRecord(relationEntity, record.key, record))
                  }

                  for (let sr of selectedRecords) {
                        items = items.filter(i => i.key !== sr.key)
                  }

                  if (rowSelectionType === 'checkbox') {

                        setSelectedRecords(selectedRecords.concat(items))
                  }
                  if (rowSelectionType === 'radio') {
                        setSelectedRecords(items)
                  }

                  setCheckSelectedRows({selectedRows: [], selectedRowKeys: []})


            }
      }

      //动态设置选中的记录可用状态
      function onHandleCheckboxProps(selectedRowKeys: string[], r: IObject) {

            let selectedRecord = undefined
            for (let sr of selectedRecords) {
                  if (sr.key === r.key) {
                        selectedRecord = r
                        break
                  }
            }

            if (selectedRecord) {
                  return {
                        disabled:true
                  }
            }

            return {
                  disabled: false,
                  checked: false
            }

      }

      // 选中或取消选中事件触发
      const onHandleSelectionChange = (selectedRowKeys: Key[], selectedRows: IObject[]) => {
            if (selectedRowKeys.length > 0) {
                  setMoveToSelectedDisabled(false)
            } else {
                  setMoveToSelectedDisabled(true)
            }
            setCheckSelectedRows({selectedRowKeys: selectedRowKeys, selectedRows: selectedRows})
      }

      const getTableRowSelection = () => {
            return {
                  getCheckboxProps: function (selectedRowKeys: string[], selectedRecord: IObject) {
                        return onHandleCheckboxProps(selectedRowKeys, selectedRecord)
                  },
                  onChange: function (selectedRowKeys: Key[], selectedRows: IObject[]) {
                        onHandleSelectionChange(selectedRowKeys, selectedRows)
                  },
                  selectionType: rowSelectionType
            }
      }

      return (
          <>

                <Modal
                    width={1000}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}>
                      <EntityDataTableView
                          ref={tableViewRef}
                          namespace={relationEntity?.namespace}
                          entity={relationEntity?.name}
                          newCreateView={true}
                          deleteView={false}
                          uploadView={false}
                          searchView={true}
                          breadCrumbView={false}
                          onItemEditable={false}
                          filter={undefined}
                          onSearchHandle={() => {
                                setDrawerVisible(true)
                          }}
                          tableRowSelection={getTableRowSelection()}
                      />

                      <Divider/>
                      <div><Button type={"primary"} disabled={moveToSelectedDisabled}
                                   onClick={() => onMoveToSelected()}>下移选中的记录</Button></div>
                      <Divider/>

                      <div style={{marginTop: 10, overflowX: "scroll"}}>
                            <List
                                style={{height: 300}}
                                loading={false}
                                itemLayout="horizontal"
                                dataSource={selectedRecords}
                                renderItem={item => (
                                    <List.Item
                                        key={item.key}
                                        actions={[<a key={`delete-edit-${item.key}`} onClick={() => removeSelectItem(item)}>删除</a>]}
                                    >
                                          <List.Item.Meta
                                              title={item.title}
                                              description={item.content}
                                          />
                                    </List.Item>

                                )}
                            />
                      </div>

                      {
                            drawerVisible ? <DrawerFormComponent metaEntity={metaEntity}
                                                                 drawerVisible={drawerVisible}
                                                                 drawerToDom={false}
                                                                 setDrawerVisible={(v: boolean) => {
                                                                       setDrawerVisible(v)
                                                                 }}
                                                                 onSearchSubmit={() => {

                                                                 }}
                            /> : undefined
                      }

                </Modal>
          </>
      );
};

export default RelationModal;
