export interface EntityDataTypes {
      [index: string]: {}[]
}


export interface EntityDataDataFrameTypes {

      schema: Schema[]
      rows: [][]

}

export interface Schema {
      name: string
      data_type: string
}

export interface DataColumn {
      title: string
      dataIndex: string
      key: string
}


/**
 * {
    "lineConnection": {
      "edges": [
        {
          "node": {
            "id": "ALypKi2nR",
            "name": "产线1"
          }
        },
        {
          "node": {
            "id": "1lP6Sdhng",
            "name": "产线2"
          }
        }
      ],
      "pageInfo": {
        "endCursor": "aW90ZGJfbW9kdWxlX3Rlc3QvbGluZS9pZD0xbFA2U2Robmc=",
        "hasNextPage": true,
        "hasPreviousPage": false,
        "startCursor": "aW90ZGJfbW9kdWxlX3Rlc3QvbGluZS9pZD1BTHlwS2kyblI="
      }
    }
  }
 */

export interface EntityConnectionDataTypes {
      edges: RecordNode[]
      pageInfo: IPageInfo
      _aggregate?: Aggregate
      tsConfig?: TsConfig

}


interface Aggregate {
      total_count: number
}

export interface RecordNode {
      node: { [index: string]: any }
}

export interface IPageInfo {
      endCursor: string
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      pageSize: number
      total: number
      current: number
}

export class PageInfo implements IPageInfo {
      public endCursor: string = ''
      public hasNextPage: boolean = false
      public hasPreviousPage: boolean = false
      public startCursor: string = ''
      public pageSize: number = 10
      public total: number = 0
      public current: number = 0

      constructor(pageSize: number)
      constructor(pageSize: number, hasPreviousPage?: boolean, startCursor?: string, hasNextPage?: boolean, endCursor?: string, total?: number, current?: number) {
            this.pageSize = pageSize
            this.hasPreviousPage = hasPreviousPage ? hasPreviousPage : false
            this.startCursor = startCursor ? startCursor : ''
            this.hasNextPage = hasNextPage ? hasNextPage : false
            this.endCursor = endCursor ? endCursor : ''
            this.total = total ? total : 0
            this.current = current  ? current : 0
      }

}

export interface TsConfig {
      rowDimensionNumber: number
      totalLine: number

}

/**
 * {
  lineConnection(first:2) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
 */


export function QueryEntityDataTemplate(
    table: string,
    columns: string[],
    filter: string) {


      return `{
        ${table} ${filter === undefined || filter === 'undefined' || filter === '' ? '' : `(filter:{${filter}})`} {
               ${columns.join(" ")}
        }
  }`

}

export function QueryEntityConnectionTemplate(isNextPage: boolean,
                                              table: string,
                                              cursor: string,
                                              pageSize: number,
                                              columns: string[],
                                              filter: string) {


      if (pageSize === -1) {
            return `{
        ${table} ${filter === undefined || filter === 'undefined' || filter === '' ? '' : `(filter:{${filter}})`} {
               ${columns.join(" ")}
        }
  }`
      }

      return `query {
  ${table}Connection(${filter === undefined || filter === 'undefined' ? '' : `filter:{${filter}},`} ${isNextPage ? 'after' : 'before'}:"${cursor === '-1' ? '' : cursor}",${isNextPage ? 'first' : 'last'}:${pageSize}) {
    edges {
      node {
        ${columns.join(" ")}
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`
}

export function QueryEntityConnectionWithOffsetTemplate(
    table: string,
    current: number,
    pageSize: number,
    offset: number,
    columns: string[],
    filter: string) {

      // const offset = (current - 1) * pageSize

      return `query {
  ${table}Connection(${filter === undefined || filter === 'undefined' || filter === '' ? '' : `filter:{${filter}},`} limit:${pageSize},offset:${offset === -1 ? (current - 1) * pageSize : offset}) {
    edges {
      node {
        ${columns.join(" ")}
      }
    }
    pageInfo {
      total
    }
    tsConfig {
      rowDimensionNumber
    }
  }
}`
}

export function QueryRelationshipDataConnectionTemplate(foreignTable: string, filter: string) {

      return `query {
  ${foreignTable}Connection (filter:{${filter}}){
    _aggregate {
     total_count
    }
  }
}`

}


/**
 * mutation m1 {
  createunit(data:{name:"", line:{id:""}}) {
    id
    name
  }
}
 */

export function CreateEntityDataTypeTemplate(entity: string, data: string, columns: string[]) {

      return `
        mutation ${entity} {
            create${entity}(data:{${data}}){
              ${columns.join(" ")}
            }
        }
  `
}


export function deleteEntityDataTypeRecordTemplate(entity: string,identity:string, columns: string[]) {

      /**
       * mutation d {
  deleteBatchedarea(filter:{id_in_:[8,9]}){
    id
    name
  }
}
       */
      return `
        mutation ${entity} {
            deleteBatched${entity}(filter:{${identity}}){
              ${columns.join(" ")}
            }
        }
  `
}

export function updateEntityDataTypeTemplate(entity: string,identity:string, data: string, columns: string[]) {

      return `
        mutation ${entity} {
            update${entity}(identity:{${identity}}, data:{${data}}){
              ${columns.join(" ")}
            }
        }
  `
}
export function updateRelationEntityDataTypeTemplate(entity: string, updateExp:string, deleteExp: string[], createExp: string[]) {

      return `
        mutation ${entity} {
        
           ${updateExp}
           
           ${deleteExp.join("\n")}
           
           ${createExp.join("\n")}
        }
  `
}
// 删除文件对象
export function DeleteFileObjectTemplate(entity: string, file_object_name:string) {

      /**
       *
         mutation {
              deleteBatchedfs_entity(filter: {name: "数据库系统实现（第二版）.pdf"}) {
                name
              }
         }

       */
      return `
        mutation ${entity} {
            deleteBatched${entity}(filter:{name: "${file_object_name}"}){
                  name
            }
        }
  `
}
