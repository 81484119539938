/* eslint-disable no-continue */
//@ts-nocheck
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {
      Select,
      Button, Input
} from 'antd';
import './DataForm.less';
import {
      ChildValue,
      FormItem,
      GenerationDimensionMappingEntity, GenerationEntityPrimariesColumns,
      GenerationQueryRelatedRecordPromiseForOne,
      GenerationQueryRelatedRecordsPromiseForList,
      generationSelectedRelationRecord, ItemChild, matchMappingAccessAttributeName,
      matchRelationship
} from "./useUpsertEntityData";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType, RelationTypeType} from "../../api/types/RelationTypes";
import RelationModal, {SelectedItem} from "./RelationModal";
import {formattedDisplay, generationKeyForEntityRecord} from "../EntityDataTableView/useTableView";
import {IObject} from "../EntityDataTableView/typings";


interface ISelectProps {
      metaEntity: EntityType
      relationEntity: EntityType
      relationships: RelationType[]
      formItem: FormItem
      selectMode: string
      modifyRecord:IObject
      onHandleSelectedValues: (values: string[]) => void
      onChange: (v:string[]) => void
      value: string[]
}



const SelectWithRelationship: React.FC<ISelectProps> = props => {
      const {
            metaEntity,
            relationEntity,
            relationships,
            formItem,
            selectMode,
            modifyRecord,
            onHandleSelectedValues=(v:string[]) =>{},
            onChange,
            value

      } = props;

      const {Option, OptGroup} = Select;
      const [lastedSelectedItems, setLastedSelectedItems] = useState([])
      const [modalVisible, setModalVisible] = useState(false)
      const [selectedLoading, setSelectedLoading] = useState(false)

      const copyFormItem = (childValues:ChildValue[]):FormItem => {
            let newFormItem = new FormItem()
            newFormItem.name = formItem.name
            newFormItem.type = formItem.type
            newFormItem.label = formItem.label
            newFormItem.rules = formItem.rules
            newFormItem.editable = formItem.editable

            let itemChild = new ItemChild();
            itemChild.name = formItem.child.name
            itemChild.values = childValues

            newFormItem.child = itemChild

            return newFormItem
      }
      const initSelectedItems = (values:string[]) => {

            // 初始化选择的记录
            const initSelectedItems:SelectedItem[] = []
            values.map(i => {
                  initSelectedItems.push(generationSelectedRelationRecord(relationEntity,
                      generationKeyForEntityRecord(relationEntity,i, 0), i))
            })
            if (initSelectedItems.length > 0) {
                  //备份formItem的初始记录
                  let childValues = selectedValuesToChildValue(initSelectedItems);
                  formItem.fromFormItem = copyFormItem(childValues)
                  setLastedSelectedItems(initSelectedItems)
            }

      }
      //设置默认选中的值
      useEffect(() =>{
            if (modifyRecord && formItem.editable) {

                  setSelectedLoading(true)

                  let format: string = formattedDisplay(formItem.name, metaEntity, relationships)
                  if (format === '列表'){
                        const promise = GenerationQueryRelatedRecordsPromiseForList(modifyRecord, metaEntity, formItem.name, relationEntity, relationships)
                        promise.then(result => {
                              let dl = result.data[relationEntity.name]
                              initSelectedItems(dl)
                              onChange(dl.map(d => JSON.stringify(d)))
                              setSelectedLoading(false)
                        }).catch(err => {
                              console.log(err)
                        })
                  }
                  if (format === '对象'){
                        const promise = GenerationQueryRelatedRecordPromiseForOne(modifyRecord, metaEntity,formItem.name, relationEntity, relationships)
                        promise.then(result => {
                              const primaryInfoOfRelationship = result.data[metaEntity.name][0][formItem.name]
                              // 判断是否关联
                              const primaryCols = GenerationEntityPrimariesColumns(relationEntity)
                              let primaryNullOfRelation = false
                              for (let col of primaryCols) {
                                    if (!primaryInfoOfRelationship[col]) {
                                          primaryNullOfRelation = true
                                          break
                                    }
                              }
                              const dl = primaryNullOfRelation ? [] : [primaryInfoOfRelationship]
                              initSelectedItems(dl)
                              // 自身更新Select组件上记录
                              onChange(dl.map(d => JSON.stringify(d)))
                              setSelectedLoading(false)

                        }).catch(err => {
                              console.log(err)
                        })
                  }
            }

      }, [modifyRecord])


      const onSelectChange = (vt:string[]) => {
            onChange(vt) // 触发更新Select自身组件的onChange事件，并自动更新数据
            let lastItems:SelectedItem[] = []
            for (let v of vt) {
                  for (let lsi:SelectedItem of lastedSelectedItems) {
                       if (lsi.content === v) {
                             lastItems.push(lsi)
                             break
                       }
                  }
            }
            setLastedSelectedItems(lastItems)
      }

      const selectedValuesToChildValue = (selectedItems):ChildValue[] => {

            let childValues: ChildValue[] = []
            for (let lsi: SelectedItem of selectedItems) {
                  let cv = new ChildValue()
                  cv.value = lsi.content
                  cv.index = lsi.key
                  childValues.push(cv)
            }

            return childValues
      }


// 回调更新formItem上的值
      useEffect(() =>{
            if (lastedSelectedItems) {
                  formItem.child.values = selectedValuesToChildValue(lastedSelectedItems)
                  onHandleSelectedValues(formItem)
            }
      }, [lastedSelectedItems])


      return (
          <>
                <div style={{display: "inline-block"}}>
                      <div style={{display: "inline-block", width: 250}}>
                            <Select key={formItem.child.name}
                                    disabled={!formItem.editable}
                                    mode={selectMode}
                                    allowClear={true}
                                    value={value}
                                    onChange={(values) => {
                                          onSelectChange(values)
                                    }}
                                    maxTagCount={10}
                            >
                                  {
                                        value?.map((v, index) => {
                                              return (
                                                  <Option key={v} value={v}>{v}</Option>
                                              )
                                        })
                                  }
                            </Select>
                      </div>

                      <div style={{display: "inline-block", marginLeft:5}}>
                            <Button disabled={!formItem.editable}
                                    loading={selectedLoading}
                                    onClick={() => {
                                  setModalVisible(!modalVisible)
                            }}>{'选择'}</Button>

                            {
                                  modalVisible ? <RelationModal metaEntity={metaEntity}
                                                                relationEntity={relationEntity}
                                                                relationships={relationships}
                                                                accessAttribute={formItem.name}
                                                                modalVisible={modalVisible}
                                                                lastedSelectedItems={lastedSelectedItems}
                                                                modalVisibleCallback={(v: boolean) => {
                                                                      setModalVisible(v)
                                                                }}
                                                                // 回填选中的记录
                                                                refillingNewSelectedItems={(records) => {
                                                                      onChange(records.map(i => i.content))
                                                                      setLastedSelectedItems(records)
                                                                }}
                                  /> : undefined
                            }
                      </div>
                </div>

          </>
      );
};

export default SelectWithRelationship;
