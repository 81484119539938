import {useEffect, useReducer} from "react";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import {uploadFileObjectReducer} from "./reducer";
import {ACTION_TYPE, IFileObjectState, kUploadFile} from "./typings";
import entityApi from "../../api/requests/entityReqs";
import {UploadRequestOption} from "rc-upload/lib/interface";
import {EntityType} from "../../api/types/EntityTypes";
import {
      FormItem, generationCreateFileObjectAttributesExpression,
       generationCreateFileObjectRelationshipExpression,
} from "./useUpsertEntityData";
import {ResponseData} from "../../sdk/request";
import {RelationType} from "../../api/types/RelationTypes";
import {message} from "antd";
import {IObject} from "../EntityDataTableView/typings";

const buildUploadPromiseLike = (namespace: string, entityName: string, file: kUploadFile, customAttributes: IObject[], relationshipAttributes: IObject[]): Promise<ResponseData<any>> => {

      const uploadAttributes = JSON.stringify([{file_name: file.name, file_value: customAttributes}])
      const uploadRelationshipAttributes = JSON.stringify([{file_name: file.name, file_value: relationshipAttributes}])
      let formData: FormData = new FormData();
      // @ts-ignore
      formData.append('files', file);
      formData.append('attributes', uploadAttributes)
      formData.append('relationships', uploadRelationshipAttributes)
      // @ts-ignore
      return entityApi.uploadFileObjects(namespace, entityName, formData)


}
export const initState = (formItems: FormItem[]): IFileObjectState => {

      return {
            formItems: formItems,
            fileList: new Array<kUploadFile>(),
            preview: {
                  image: '',
                  visible: false,
                  title: '',
            },
            handleChange: (info: UploadChangeParam) => {
            },
            handlePreview: async (file: kUploadFile) => {
            },
            beforeUpload: (file: RcFile) => {
            },
            customRequest: (options: UploadRequestOption) => {
            },
            submit: (form: any, fieldsValue: {}, callback: Function) => {
            },
            removeFile: (file: kUploadFile) => {
            },
            removeOnlineFile: (file: kUploadFile) => {
            },
            spinning: false,
            object_storage:false,
      }
}

export const useDataFormState = (namespace: string, entity: EntityType, relationships: RelationType[], formItems: FormItem[]): IFileObjectState => {
      const [fileObjectState, dispatch] = useReducer(uploadFileObjectReducer, initState(formItems))

      // 上传前文件校验
      fileObjectState.beforeUpload = (file: RcFile) => {
      }

      // 提交数据

      fileObjectState.submit = (form: any, fieldsValue: { [k: string]: any }, callback: Function) => {
            // @ts-ignore
            // 查找自定义属性
            let customAttributes = generationCreateFileObjectAttributesExpression(entity, relationships, fileObjectState.formItems, fieldsValue)
            let relationshipAttributes = generationCreateFileObjectRelationshipExpression(entity, relationships, fileObjectState.formItems, fieldsValue)

            let itemForm = undefined
            for (let item of fileObjectState.formItems) {
                  if (item.child.name === '上传') {
                        itemForm = item
                        break
                  }
            }

            if (!itemForm) {
                  return
            }
            if (itemForm.child.values.length === 0) {
                  form.resetFields(["files"])
                  form.submit()
                  return
            }



            // 构建异步上传任务
            const promises: Function[] = []
            for (let cv of itemForm.child.values) {
                  let uploadFile = cv.value as kUploadFile
                  if (uploadFile.onLineStatus !== 'success') {
                        const p = (): Map<kUploadFile, ResponseData<any>> => {
                              // uploadFile.status = 'uploading'
                              // uploadFile.percent = Math.floor((Math.random()*60)+1);
                              //
                              // dispatch({
                              //       type: ACTION_TYPE.UPDATE_FILE_OBJECT,
                              //       payload: uploadFile
                              // })
                              //@ts-ignore
                              return buildUploadPromiseLike(namespace, entity.name, uploadFile, customAttributes, relationshipAttributes)
                                  .then(resp => {
                                        return new Map<kUploadFile, ResponseData<any>>().set(uploadFile, resp)
                                  })
                                  .catch(err => {
                                        return new Map<kUploadFile, ResponseData<any>>().set(uploadFile, err)
                                  })
                        }

                        promises.push(p)
                  }

            }

            // 多任务合并上传
            function mergePromise() {
                  async function run() {
                        let uploadResultCollect: Array<Map<kUploadFile, ResponseData<any>>> = []
                        for (let promise of promises) {
                              let resp: Map<kUploadFile, ResponseData<any>> = await promise()
                              uploadResultCollect.push(resp)
                        }
                        return uploadResultCollect
                  }

                  return run()
            }

            // 顺序上传结果
            mergePromise().then((ret: Array<Map<kUploadFile, ResponseData<any>>>) => {
                  let errFiles = []
                  for (let e of ret) {
                        e.forEach((responseData, uploadFile) => {
                              // 上传失败
                              if (responseData === undefined || responseData.code !== 0) {
                                    errFiles.push(uploadFile)
                                    uploadFile.status = 'error'
                                    uploadFile.onLineStatus = 'error'
                              } else {// 上传成功
                                    uploadFile.status = 'success'
                                    uploadFile.onLineStatus = 'success'

                              }
                              dispatch({
                                    type: ACTION_TYPE.UPDATE_FILE_OBJECT,
                                    payload: uploadFile
                              })

                        })
                  }

                  // 无错误文件
                  if (errFiles.length === 0) {
                        //通知父组件刷新列表
                        form.resetFields()
                        fileObjectState.formItems = []
                        callback({})
                  }

            })

      }
      // 上传临时文件
      fileObjectState.customRequest = (options: UploadRequestOption) => {
            const {file} = options
            const uploadFile = file as kUploadFile

            for (let formItem of fileObjectState.formItems) {
                  if (formItem.type === "files" && formItem.child.values.length > 0) {
                        const repeatFiles = formItem.child.values.filter(v => v.value.name === uploadFile.name)
                        if (repeatFiles.length > 0){
                              message.warn(`文件${uploadFile.name}已存在等待上传队列中`);
                              return
                        }
                  }
            }

            uploadFile.status = "done"
            uploadFile.onLineStatus = "waiting"
            uploadFile.percent = 100
            dispatch({
                  type: ACTION_TYPE.ADD_FILE_OBJECT,
                  payload: uploadFile
            })
      }

      // 移除错误文件
      fileObjectState.removeFile = (file: kUploadFile) => {

            if (file.onLineStatus === 'error' || file.onLineStatus === 'waiting') {
                  // 从状态中移除错误文件
                  dispatch({
                        type: ACTION_TYPE.REMOVE_FILE_OBJECT,
                        payload: file
                  })
            }

            if (file.onLineStatus === 'success') {
                  dispatch({
                        type: ACTION_TYPE.REMOVE_ONLINE_FOR_SPINNING,
                        payload: true
                  })
                  // 从云盘中移除文件
                  //@ts-ignore
                  entityApi.deleteFileObject(namespace, entity.name, file.name)
                      .then(resp => {
                            dispatch({
                                  type: ACTION_TYPE.REMOVE_FILE_OBJECT,
                                  payload: file
                            })
                      }).catch(error => {
                        console.log(error)
                  }).finally(() => {
                        dispatch({
                              type: ACTION_TYPE.REMOVE_ONLINE_FOR_SPINNING,
                              payload: false
                        })
                  })

            }

      }


      return fileObjectState

}