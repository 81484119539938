//@ts-nocheck
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";
import {CachePageInfo, DataSource} from "./dataEntity";
import {DataSourceType} from "../../api/types/DataSourceTypes";
import {IPageInfo} from "../../api/types/EntityDataTypes";

export interface IObject {
  [index: string]: any;
}


export interface IAction {
  type: ACTION_TYPE,
  payload: any
}

export enum ACTION_TYPE {
  SETTING_METAENTITY = 'settingMetaEntity',
  SETTING_NAMESPACE_DATASOURCES = 'setting_namespace_datasources',
  SETTING_RELATIONSHIPS = 'settingRelationships',
  SETTING_DATASOURCE = 'settingDataSource',
  SETTING_LOADING = 'settingLoading',
  SETTING_DRAWER_VISIBLE = 'setting_drawer_visible',
  SETTING_ROW_SELECTED_DATA = 'setting_row_selected_data',
  SETTING_PAGINATION = 'settingPagination',
  SETTING_PUSH_CURSORS = 'setting_push_cursors',
  SETTING_POP_CURSORS = 'setting_pop_cursors',
  SETTING_CHANGE_PAGINATION = 'setting_change_pagination',
  SETTING_TIMESERIES = 'settingTimeSeries',
  SETTING_FILTER = 'setting_filter',
  SETTING_BREADCRUMB_ITEMS = 'setting_breadcrumb_items',
  SETTING_ENTITY = 'setting_entity',
  SETTING_TS_ESTIMATE_PAGE_DATA = 'setting_ts_estimate_page_data',
  SETTING_TS_OFFSET_MAPPING_PAGE_GROUP = 'setting_ts_offset_mapping_page_group',
  SETTING_IS_PUSH_BREAD_CRUMB_ITEM = 'setting_is_push_bread_crumb_item',
  SETTING_IS_POP_BREAD_CRUMB_ITEM = 'setting_is_pop_bread_crumb_item',
  SETTING_CREATE_ENTITY_DATA_WRITEABLE = 'setting_create_entity_Data_Writeable',
  SETTING_MODIFY_ENTITY_RECORD = 'setting_modify_entity_record',
  SETTING_UPLOAD_ENTITY_DATA_WRITEABLE = 'setting_upload_entity_data_writeable',
}


export interface IState {
  metaEntity: EntityType
  relationships: RelationType[]
  metaDataSources: Map<number, DataSourceType>
  createEntityDataWriteable: boolean
  uploadEntityDataWriteable: boolean
  modifyEntityRecord: IObject
  resetModifyEntityRecord: () => void
  dataSource: DataSource
  loading: boolean
  pagination: any
  cursors: Map<number, IPageInfo>
  current: number
  total: number
  changePagination: boolean
  timeSeries: boolean
  filter: string
  entity: string
  tsEstimatePageData: Map<number, any>
  offsetMappingPageGroup: Map<number, number[]>
  onRefreshTableView: () => void
  popBreadCrumbItem: (item: BCItem) => void
  pushBreadCrumbItem: (col: string, record: any) => void
  modifyRecord: (r: IObject) => void
  breadCrumbItems: BCItem[]
  isPopBreadCrumbItem: boolean
  isPushBreadCrumbItem: boolean
  drawerVisible: boolean
  drawerVisibleFunc: (visible:boolean) => void
  rowSelection:{selectedRowKeys:[], selectedRows:[]}
  onRowSelectionChange:({selectedRowKeys:[], selectedRows:[]}) => void
  onDeleteSelectedRows:() => void
}



export class BCItem {
  public itemId: string
  public entityRecord: any
  public queryFilter: string
  public metaEntity: EntityType | string
  public dimensionMetaEntity: EntityType

  constructor(
    itemId: string,
    entityRecord: any,
    queryFilter: string,
    metaEntity: EntityType | string,
    dimensionMeteEntity: EntityType){
    this.itemId = itemId
    this.entityRecord = entityRecord
    this.queryFilter = queryFilter
    this.metaEntity = metaEntity
    this.dimensionMetaEntity = dimensionMeteEntity
  }

  getContent() {

    if (this.metaEntity && typeof this.metaEntity === 'object') {
      let values = []
      if (this.metaEntity.attributes) {
        for (let attr of this.metaEntity.attributes) {
          if (attr.is_primary) {
            values.push(this.entityRecord[attr.name])
          }
        }
      }
      // line(x,y,z)->unit
      return `${this.metaEntity.name}(${values.join(",")}) -> ${this.dimensionMetaEntity.name}`
    } else {
      return ` -> ${this.metaEntity}`
    }

  }
}
