import {Button, Upload} from "antd";
import React from "react";
import {useUploadState} from "./useUploadCsv";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";

interface Props {
      namespace: string
      entity: EntityType
      relationships:RelationType[]
      disabled: boolean
      onRefreshTableView: (v: boolean) => void
}


const UploadCsvData: React.FC<Props> = ({
                                              namespace,
                                              entity,
                                              relationships,
                                              disabled,
                                              onRefreshTableView
                                        }) => {


      const uploadState = useUploadState(namespace, entity, disabled, onRefreshTableView);
      const uploadData = () => {
            console.log("---- upload -----")

      }

      return (

          <Upload {...uploadState.uploadComponentProps}>
                <Button type={"primary"}
                        onClick={uploadData}
                        loading={uploadState.isLoading}
                        disabled={disabled}>{'上传CSV'}</Button>
          </Upload>
      )


}


export default UploadCsvData;
