import {IObject} from "./typings";
import {IPageInfo, PageInfo, TsConfig} from "../../api/types/EntityDataTypes";

export interface DataEntityTypes {
  schema: Schema[]
  rows: any[][]
}

export interface Schema {
  name: string
  data_type: string
}

export class Column {
  index?: string;
  title?: string;
  dataIndex?: string;
  key?: string;
  dataType?: string;
  dataTypeOptions?: {
    [optionName: string]: any
  };
}


export class DataSource {
  columns: Column[] = [];
  records: IObject[] = [];
  pageInfo?: IPageInfo;
  tsConfig?: TsConfig
}

export interface CachePageInfo {
  pageInfos:Map<number, any>
}



