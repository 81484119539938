/* eslint-disable no-continue */
//@ts-nocheck
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {UploadOutlined, PlusOutlined} from '@ant-design/icons';
import {
      Input,
      Select,
      InputNumber,
      Space,
      Drawer,
      Button,
      Form,
      Row,
      Col
} from 'antd';
import './DrawerFormStyle.less';
import {EntityType} from "../../../api/types/EntityTypes";


interface IDrawerFormProps {
      metaEntity: EntityType
      drawerVisible: boolean
      setDrawerVisible: (v: boolean) => void
      onSearchSubmit: () => void
      drawerToDom:boolean
}

const DrawerFormComponent: React.FC<IDrawerFormProps> = props => {
      const {
            metaEntity = undefined,
            drawerVisible = false,
            setDrawerVisible = (v: boolean) => {
            },
            onSearchSubmit = () => {
            },
            drawerToDom = true

      } = props;
      const [form] = Form.useForm();

      // const [visible, setVisible] = useState(drawerVisible)

      const onClose = () => {
            setDrawerVisible(false)
      }

      const onFormFinish = () => {
            form
                .validateFields()
                .then(values => {
                      console.log("form finish values =>", values)
                      onSearchSubmit()
                      onClose()
                })
      }

      const onOK = () => {
            form.submit()
      }

      return (
          <>


                      <Drawer
                          title={`搜索: ${metaEntity?.name}`}
                          width={420}
                          placement="right"
                          onClose={onClose}
                          visible={drawerVisible}
                          destroyOnClose={(e) => {
                                console.log("Drawer =>destroyOnClose", e)
                          }}

                          bodyStyle={{paddingBottom: 80}}
                          maskClosable={true}
                          getContainer={drawerToDom}
                          style={{position:"absolute"}}
                          extra={
                                <Space>
                                      <Button onClick={onClose}>取消</Button>
                                      <Button onClick={onOK} type="primary">
                                            确定
                                      </Button>
                                </Space>
                          }
                      >
                            <Form layout="vertical"
                                  form={form}
                                  labelCol={{span: 4}}
                                  wrapperCol={{span: 18}}
                                  onFinish={onFormFinish}
                                  preserve={false}
                                  hideRequiredMark
                            >
                                  <Row gutter={16}>
                                        <Col span={12}>
                                              <Form.Item
                                                  name="name"
                                                  label="Name"
                                              >
                                                    <Input placeholder="Please enter user name"/>
                                              </Form.Item>
                                        </Col>
                                  </Row>

                                  <Row gutter={16}>
                                        <Col span={12}>
                                              <Form.Item
                                                  name="display"
                                                  label="Display"
                                              >
                                                    <Input placeholder="Please enter user name"/>
                                              </Form.Item>
                                        </Col>
                                  </Row>

                                  <Row gutter={16}>
                                        <Col span={12}>
                                              <Form.Item
                                                  name="id"
                                                  label="ID"
                                              >
                                                    <Input placeholder="Please enter user name"/>
                                              </Form.Item>
                                        </Col>
                                  </Row>

                            </Form>

                      </Drawer>

          </>
      );
};

export default DrawerFormComponent;
