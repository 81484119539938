import env from 'day4-utils/es/env';
import SecurityService from './SecurityService.js';

//const PUBLIC_URL = env.PUBLIC_URL
const PUBLIC_URL = '/modeler';

export const ss = new SecurityService(
  env.DAY4_APP_AUTH_SERVER,
  window.location.origin + PUBLIC_URL + '/static/'
);

console.log(ss);
