//@ts-nocheck
import {EntityAttributeType, EntityType} from "../../api/types/EntityTypes";
import {RelationType, RelationTypeType} from "../../api/types/RelationTypes";
import {AnyMap} from "immer/dist/types/types-internal";
import {EntityDataTypes} from "../../api/types/EntityDataTypes";
import moment from "moment";
import {ANNOTATION_TYPE, RESOURCE_TYPE} from "../../api/types/CommonTypes";
import {IObject} from "../EntityDataTableView/typings";
import {formattedDisplay} from "../EntityDataTableView/useTableView";
import entityApi from "../../api/requests/entityReqs";
import {SelectedItem} from "./RelationModal";

export class FormItem {
      public name: string = ''
      public type: string = ''
      public category: string = ''
      public label: string = ''
      public rules: ItemRule[] = []
      public child: ItemChild = new ItemChild()
      public editable: boolean = true
      public fromFormItem:FormItem = undefined //备份原始FormItem：记录关系上原始数据。

}

export class ItemRule {
      public required: boolean = false
      public message: string = ''

}

export class ItemChild {
      public name: string = ''
      public additional: string = ''
      public values: ChildValue[] = []
      public defaultValues: ChildValue[] = []
}

export class ChildValue {
      public index: string = '-1'
      public value: any = ''
      public checked?: boolean = false
}


export class RelationshipRecords {
      public name: string = '' // 记录访问属性名称
      public records: { [index: string]: any }[] = []  // 关系记录
      public relRecords: { [index: string]: any }[] = []  // 关系记录
}

export function matchRelationship(metaEntity: EntityType, relationEntity: EntityType, relationships: RelationType[]): RelationType {
      for (let rs of relationships) {
            if (rs.object_type1?.name === metaEntity.name && rs.object_type2?.name === relationEntity.name) {
                  return rs
            }
            if (rs.object_type2?.name === metaEntity.name && rs.object_type1?.name == relationEntity.name) {
                  return rs
            }
      }
}

export function matchMappingAccessAttributeName(metaEntity:EntityType, accessAttributeName:string, relationship:RelationType):string {
      // 根据当前的实体和访问属性，匹配映射对方的访问属性
      if (relationship.object_type_name1 === metaEntity.name && relationship.attribute_name1 === accessAttributeName) {
            return relationship.attribute_name2
      }
      if (relationship.object_type_name2 === metaEntity.name && relationship.attribute_name2 === accessAttributeName) {
            return relationship.attribute_name1
      }
}

export function matchRelationshipEntity(relationships: RelationType[], metaEntity: EntityType, dimensionName: string): EntityType | undefined {
      for (let relation of relationships) {
            if (relation.object_type_name1 === metaEntity.name && relation.attribute_name1 === dimensionName) {
                  // if (relation.relation_multiplicity === 'ONE2ONE'
                  //     || relation.relation_multiplicity === 'MANY2MANY'
                  //     || relation.attributes?.length > 0
                  // ) {
                  //       return relation.object_type2
                  // }
                  // return

                  return relation.object_type2
            }
            if (relation.object_type_name2 === metaEntity.name && relation.attribute_name2 === dimensionName) {
                  // if (relation.relation_multiplicity === 'ONE2MANY'
                  //     || relation.relation_multiplicity === 'MANY2MANY'
                  //     || relation.attributes?.length > 0
                  // ) {
                  //       return relation.object_type1
                  // }
                  // return
                  return relation.object_type1
            }
      }
}

// 生成实体主键的集合列
export function GenerationEntityPrimariesColumns(metaEntity: EntityType): string[] {

      let cols: string[] = []
      if (metaEntity && metaEntity.attributes) {
            for (let attr of metaEntity.attributes) {
                  if (attr && attr.is_primary) {
                        cols.push(attr.name)
                  }
            }
      }
      return cols
}

// 收集实体中主键属性
export function GenerationEntityPrimariesAttrs(metaEntity: EntityType): EntityAttributeType[] {

      let attrs: EntityAttributeType[] = []
      if (metaEntity && metaEntity.attributes) {
            for (let attr of metaEntity.attributes) {
                  if (attr && attr.is_primary) {
                        attrs.push(attr)
                  }
            }
      }
      return attrs
}

// 生成维度列映射的实体
export function GenerationDimensionMappingEntity(metaEntity: EntityType, relationships: RelationType[]): AnyMap {
      let mappingRelationshipEntity = new Map()
      if (metaEntity && metaEntity.attributes) {
            for (let attr of metaEntity.attributes) {
                  if (attr.type === 'relation') {
                        let entity = matchRelationshipEntity(relationships, metaEntity, attr.name);
                        if (entity) {
                              mappingRelationshipEntity.set(attr.name, entity)
                        }

                  }
            }
      }
      return mappingRelationshipEntity
}
export function GenerationQueryRelatedRecordsPromiseForList(modifyEntityRecord: IObject,
                                                            metaEntity: EntityType,
                                                            accessAttributeName:string,
                                                            relationshipEntity: EntityType,
                                                            relationships:RelationType[]) {
      let pkValuesOfEntity: string[] = []
      let pkColsOfEntity = GenerationEntityPrimariesColumns(metaEntity)
      let primariesAttrs = GenerationEntityPrimariesAttrs(metaEntity)
      let relationship = matchRelationship(metaEntity, relationshipEntity, relationships);
      // 从修改的记录中，计算出主键对应的值
      for (let col of pkColsOfEntity) {
            let filterAttr = primariesAttrs.filter(a => a.name == col)[0]
            let v = modifyEntityRecord[col]
            let formatValue = formatValueByType(filterAttr.type, v)
            pkValuesOfEntity.push(`${col}:${formatValue}`)
      }
      // 构建关联对象的查询表达式 (todo: 为了区分查询结果是什么类型的？(list, object))
      let mappingAccessAttrNameByMetaEntity:string = matchMappingAccessAttributeName(metaEntity, accessAttributeName, relationship)
      const filterEl = `${mappingAccessAttrNameByMetaEntity}:{${pkValuesOfEntity.join(",")}}`
      const pkColsOfRelationship = GenerationEntityPrimariesColumns(relationshipEntity)
      return entityApi.fetchEntityData(relationshipEntity, pkColsOfRelationship, filterEl)

      // if (result.data[relationshipEntity.name] && Object.keys(result.data[relationshipEntity.name]).length > 0) {
      //       let rsr = new RelationshipRecords()
      //       rsr.name = accessAttributeName
      //       rsr.relRecords = result.data[queryTargetEntity.name]
      //       relationshipRecordsList.push(rsr)
      //
      // }

}
export function GenerationQueryRelatedRecordPromiseForOne(modifyEntityRecord: IObject,
                                                          metaEntity: EntityType,
                                                          accessAttributeName: string,
                                                          relationshipEntity: EntityType,
                                                          relationships:RelationType[]) {
      // 需要找出对象的值
      let cols = GenerationEntityPrimariesColumns(metaEntity)
      let primariesAttrs = GenerationEntityPrimariesAttrs(metaEntity)
      let colsOfRelation = GenerationEntityPrimariesColumns(relationshipEntity)

      let elOfRelationEL = `${accessAttributeName} {
                                                      ${colsOfRelation.join(",")}
                                                    }`
      let pkValuesOfEntity: string[] = []
      // 从修改的记录中，计算出主键对应的值
      for (let col of cols) {

            let filterAttr = primariesAttrs.filter(a => a.name == col)[0]
            let v = modifyEntityRecord[col]
            let formatValue = formatValueByType(filterAttr.type, v)

            pkValuesOfEntity.push(`${col}:${formatValue}`)
      }

      let queryCols: string[] = []
      queryCols.push(...cols)
      queryCols.push(elOfRelationEL)

      return entityApi.fetchEntityData(metaEntity, queryCols, pkValuesOfEntity.join(","))

      // if (result.data[metaEntity.name] && Object.keys(result.data[metaEntity.name]).length > 0) {
      //       let rsr = new RelationshipRecords()
      //       const primaryInfoOfRelationship = result.data[entity.name][0][relationEntity.name]
      //       // 判断是否关联
      //       const primaryCols = GenerationEntityPrimariesColumns(relationshipEntity)
      //       let primaryNullOfRelation = false
      //       for (let col of primaryCols) {
      //             if (!primaryInfoOfRelationship[col]) {
      //                   primaryNullOfRelation = true
      //                   break
      //             }
      //       }
      //       rsr.name = accessAttributeName
      //       rsr.relRecords = primaryNullOfRelation ? [] : [primaryInfoOfRelationship]
      //       relationshipRecordsList.push(rsr)
      // }

}

export function GenerationQueryRelatedRecordPromise(modifyEntityRecord: IObject, metaEntity: EntityType,
                                                    relationshipEntity: EntityType,
                                                    accessAttributeName: string,
                                                    relationships: RelationType[]): any[] {
      if (metaEntity && metaEntity.attributes) {
            for (let attr of metaEntity.attributes) {
                  if (attr.type === 'relation' && attr.name === accessAttributeName) {
                        let format: string = formattedDisplay(attr.name, metaEntity, relationships)
                        if (format === '列表') {
                              // factory -> areas(dimension)
                              // 其表达式：
                              // {
                              //   area(filter:{factory:{id:1}}) {
                              //     id
                              //   }
                              // }

                              // let pkValuesOfEntity: string[] = []
                              // let pkColsOfEntity = GenerationEntityPrimariesColumns(metaEntity)
                              // let primariesAttrs = GenerationEntityPrimariesAttrs(metaEntity)
                              // // 从修改的记录中，计算出主键对应的值
                              // for (let col of pkColsOfEntity) {
                              //       let filterAttr = primariesAttrs.filter(a => a.name == col)[0]
                              //       let v = modifyEntityRecord[col]
                              //       let formatValue = formatValueByType(filterAttr.type, v)
                              //       pkValuesOfEntity.push(`${col}:${formatValue}`)
                              // }
                              // // 构建关联对象的查询表达式 (todo: 为了区分查询结果是什么类型的？(list, object))
                              // let filterEl = `${metaEntity.name}:{${pkValuesOfEntity.join(",")}}`
                              // let pkColsOfRelationship = GenerationEntityPrimariesColumns(relationshipEntity)

                              return [
                                    {
                                          'entity': metaEntity,
                                          'queryTargetEntity': relationshipEntity,
                                          'relationEntity': relationshipEntity,
                                          'accessAttributeName': accessAttributeName,
                                          'formatType': format
                                    }, GenerationQueryRelatedRecordsPromiseForList(modifyEntityRecord,metaEntity, accessAttributeName, relationshipEntity, relationships)]
                        }
                        if (format === '对象') {
                              // area -> factory（dimension）
                              // 其表达式
                              // {
                              //       area (filter:{id:1}){
                              //          id,
                              //          factory {
                              //                id
                              //          }
                              //       }
                              // }

                              // 需要找出对象的值
                              // let cols = GenerationEntityPrimariesColumns(metaEntity)
                              // let primariesAttrs = GenerationEntityPrimariesAttrs(metaEntity)
                              // let colsOfRelation = GenerationEntityPrimariesColumns(relationshipEntity)
                              //
                              // let elOfRelationEL = `${relationshipEntity.name} {
                              //                         ${colsOfRelation.join(",")}
                              //                       }`
                              // let pkValuesOfEntity: string[] = []
                              // // 从修改的记录中，计算出主键对应的值
                              // for (let col of cols) {
                              //
                              //       let filterAttr = primariesAttrs.filter(a => a.name == col)[0]
                              //       let v = modifyEntityRecord[col]
                              //       let formatValue = formatValueByType(filterAttr.type, v)
                              //
                              //       pkValuesOfEntity.push(`${col}:${formatValue}`)
                              // }
                              //
                              // let queryCols: string[] = []
                              // queryCols.push(...cols)
                              // queryCols.push(elOfRelationEL)

                              //entityApi.fetchEntityData(metaEntity, queryCols, pkValuesOfEntity.join(","))

                              return [{
                                    'entity': metaEntity,
                                    'queryTargetEntity': metaEntity,
                                    'relationEntity': relationshipEntity,
                                    'accessAttributeName': accessAttributeName,
                                    'formatType': format
                              }, GenerationQueryRelatedRecordPromiseForOne(modifyEntityRecord, metaEntity, accessAttributeName, relationshipEntity, relationships)]

                        }
                  }
            }
      }

      return queryPromises
}

export const matchAnnotationOfResourceType = (metaEntity: EntityType): Annotation => {
      if (metaEntity.annotations && metaEntity.annotations.length > 0) {
            for (let ann of metaEntity.annotations) {
                  if (ann.annotation_type_name === ANNOTATION_TYPE.RESOURCE_TYPE) {
                        return ann
                  }
            }
      }
}

export const entityOfFileObject = (metaEntity: EntityType): boolean => {
      if (metaEntity.annotations && metaEntity.annotations.length > 0) {
            for (let ann of metaEntity.annotations) {
                  if (ann.annotation_type_name === ANNOTATION_TYPE.RESOURCE_TYPE) {
                        return false
                  }
            }
      }
}

function FormItemChild(relationships: RelationType[], metaEntity: EntityType, attr: EntityAttributeType,
                       dimensionMappingRecords: RelationshipRecords[],
                       modifyEntityRecord: IObject): ItemChild {
      /**
       *

       {
                    "key": 3,
                    "description": null,
                    "updated_info": null,
                    "updated_user": "admin",
                    "display_name": "1#水轮发电机",
                    "name": "PE0000000001",
                    "id": 5,
                    "created_at": "2021-11-02T15:44:49.127434Z",
                    "updated_at": "2021-11-02T15:44:49.127434Z",
                    "asset_types": {
                      "name": "asset_types",
                      "displayName": "对象",
                      "mappingCount": 1,
                      "dimensionRecord": {
                        "id": 8
                      }
                    },
                    "created_user": "admin",
                    "comp_parent_id": 4,
                    "asset_type_id": 8
             }

       {
              "key": 1,
              "created_user": "admin",
              "updated_info": null,
              "updated_at": "2021-11-02T10:24:17.388529Z",
              "description": null,
              "id": 3,
              "asset_instances": {
                "name": "asset_instances",
                "displayName": "列表",
                "mappingCount": 3
              },
              "name": "DFEM_Station",
              "display_name": "电站",
              "created_at": "2021-11-02T10:24:17.388529Z",
              "inheritance_parent_id": 1,
              "updated_user": "admin"
            }

       */



      let itemChild = new ItemChild();
      itemChild.name = attr.name

      const childItemWithRelationship = () => {
            let dimensionRecords: RelationshipRecords = undefined
            if (dimensionMappingRecords && dimensionMappingRecords.length > 0) {
                  let matchedRecord = dimensionMappingRecords.filter(r => r.name === attr.name)
                  if (matchedRecord && matchedRecord.length > 0) {
                        dimensionRecords = matchedRecord[0]
                  }
            }
            // 匹配维度列映射的记录
            if (dimensionRecords) {
                  let cvss: ChildValue[] = []
                  dimensionRecords.relRecords.map((rr, index) => {
                        let childValue = new ChildValue();
                        childValue.index = `${attr.attribute_id}_${index}`
                        childValue.value = JSON.stringify(rr)
                        cvss.push(childValue)
                  })
                  itemChild.defaultValues = cvss
                  itemChild.values = cvss
            }

      }

      // modify  record
      if (modifyEntityRecord) {
            if (attr.type === "relation") {
                  childItemWithRelationship()
            } else {
                  let childValue = new ChildValue();
                  childValue.index = String(attr.attribute_id)
                  childValue.value = modifyEntityRecord[attr.name]
                  itemChild.defaultValues = [childValue]
                  itemChild.values = [childValue]
            }
      }
      return itemChild

}

// 收集表单项
export function CollectFormItems(metaEntity: EntityType, relationships: RelationType[],
                                 dimensionMappingRecords: RelationshipRecords[],
                                 modifyEntityRecord: IObject): FormItem[] {
      let formItems: FormItem[] = []

      if (metaEntity === undefined) {
            return []
      }

      let annotationOfResourceType = matchAnnotationOfResourceType(metaEntity)

      if (metaEntity.attributes) {
            for (let attr of metaEntity.attributes) {
                  if (attr.state !== 'PUBLISH_DONE') {
                        continue
                  }

                  // if (attr.name?.trim().toLocaleLowerCase() === "id") {
                  //       continue
                  // }

                  //过滤掉托管id
                  let formItem: FormItem = new FormItem()
                  let itemRule = new ItemRule();

                  formItem.name = attr.name
                  formItem.label = attr.display
                  formItem.type = attr.type
                  formItem.category = attr.category
                  formItem.editable = true
                  itemRule.message = `请设置访问属性${attr.name}值`
                  itemRule.required = !attr.nullable


                  if (attr.type === 'relation') {
                        formItem.label = attr.name
                        itemRule.required = false
                        formItem.child = FormItemChild(relationships, metaEntity, attr, dimensionMappingRecords, modifyEntityRecord)
                        formItem.rules = [itemRule]
                        const relationEntity: EntityType = matchRelationshipEntity(relationships, metaEntity, attr.name)
                        const relationship: RelationType = matchRelationship(metaEntity, relationEntity, relationships)
                        const isPublishDone = relationEntity
                            && relationEntity.state === 'PUBLISH_DONE'
                            && relationship
                            && relationship.relation_type !== 'VIRTUAL'
                            && relationship.state === 'PUBLISH_DONE'

                        if (isPublishDone) {
                              formItem.editable = true
                        } else {
                              continue
                        }

                  } else {
                        formItem.child = FormItemChild(relationships, metaEntity, attr, dimensionMappingRecords, modifyEntityRecord)
                        formItem.rules = [itemRule]
                  }

                  // id特殊处理
                  if (attr.name === 'id' && attr.category === 'SYSTEM') {
                        // console.log("attr =>", attr)
                        // 是主键
                        // 自增
                        // 不可以编辑，并且非必填
                        if (attr.is_primary && (attr.default_value?.type === 'AUTO_INC' || attr.default_value?.type === 'RANDOM')) {
                              formItem.editable = false
                              itemRule.required = false
                        } else {
                              formItem.editable = true
                        }
                        formItem.child.additional = attr.default_value?.type

                  }

                  formItems.push(formItem)

            }

            if (annotationOfResourceType && annotationOfResourceType.params[0].Value === RESOURCE_TYPE.OBJECT_STORAGE) {

                  formItems = formItems.filter(i => {
                        if (i.name === "id") {
                              return true
                        }else return i.category !== 'SYSTEM';
                  })

                  formItems.map(i => {
                        if (i.name === 'uri') {
                              i.editable = false
                        }
                  })
                  if (!modifyEntityRecord) {
                        let formItem: FormItem = new FormItem()
                        let itemRule = new ItemRule();

                        formItem.name = 'files'
                        formItem.label = '附件'
                        formItem.type = 'files'
                        itemRule.required = true
                        itemRule.message = `请上传附件`

                        let itemChild = new ItemChild();
                        itemChild.name = '上传'
                        formItem.child = itemChild

                        formItem.rules = [itemRule]

                        formItems.push(formItem)

                  }

            }
      }
      return formItems
}

// 生成维度列映射的记录
export function GenerationRelationshipRecords(dimMappingEntity: AnyMap, resps: EntityDataTypes[]): RelationshipRecords[] {

      let rrss: RelationshipRecords[] = []
      for (let [dimColumn, entity] of dimMappingEntity) {
            for (let resp of resps) {
                  if (resp.data[entity.name] && Object.keys(resp.data[entity.name]).length > 0) {
                        let rrs = new RelationshipRecords();
                        rrs.name = dimColumn
                        rrs.records = resp.data[entity.name]
                        rrss.push(rrs)
                        break
                  }
            }
      }
      return rrss
}


export function formatValueByType(type: string, value: any): string {
      switch (type) {
            case 'int':
                  return `${value}`
            case 'float':
                  return `${value}`
            case 'datetime':
                  // let datetimeFormat = moment(Date.parse(value)).toISOString()
                  // let datetimeFormat = moment(value).format("YYYY-MM-DD HH:mm:ss").toString() // 这种格式查询引擎咱不支持
                  let datetimeFormat = value.toISOString()
                  return `"${datetimeFormat}"`
            case 'date':
                  let dateFormat = moment(value).format("YYYY-MM-DD").toString()
                  return `"${dateFormat}"`
            case 'bool':
                  return `${value}`
            default:
                  return `"${value}"`
      }
}

export function formatValueByTypeWithFileObject(type: string, value: any): any {
      switch (type) {
            case 'datetime':
                  return value.toISOString()
            case 'date':
                  return moment(value).format("YYYY-MM-DD").toString()
            default:
                  return value
      }
}

export function generationBatchedDeleteIdentityExpression(entity: EntityType, deleteData: { [index: string]: any }[]): string {
      const identityMap = new Map()
      for (let data of deleteData) {
            for (let attr of entity.identity_type.attributes) {
                  const key = attr.name
                  const value = formatValueByType(attr.type, data[attr.name]).trim()
                  if (identityMap.has(key)) {
                        identityMap.set(key, identityMap.get(key).concat(value))
                  } else {
                        identityMap.set(key, [].concat(value))
                  }
            }
      }
      const exp: string[] = []
      for (let [k, v] of identityMap) {
            exp.push(`${k}_in_:[${v}]`)
      }
      return exp.join(",")
}

export function generationIdentityExpression(entity: EntityType, submitData: { [index: string]: any }): string {
      const identityExpression: string[] = []
      for (let attr of entity.identity_type.attributes) {
            const value = submitData[attr.name]
            const formatValue = formatValueByType(attr.type, value).trim()
            identityExpression.push(`${attr.name}:${formatValue}`)


      }
      return identityExpression.join(",")
}

// 基本属性更新表达式
function updateBaseAttributeExpression(entity: EntityType,
                                       formItem: FormItem[],
                                       submitData: { [index: string]: any },
                                       identityExpByEntity: string): string {

      let exp:string = undefined

      let formItems = formItem.map(item => item)
      // 过滤关系类型
      formItems = formItems.filter(i => i.type !== "relation")
      // 过滤掉files类型
      formItems = formItems.filter(i => i.type !== "files")

      let dataExpression: string[] = []
      formItems.map(item => {
            let value = submitData[item.name]
            if (value) {
                  let tv: string = formatValueByType(item.type, value)
                  dataExpression.push(`${item.name}: ${tv}`)
            }
      })

      if (identityExpByEntity && dataExpression.length > 0) {
            let cols = GenerationEntityPrimariesColumns(entity)
            exp = `update${entity.name}(identity:{${identityExpByEntity}}, data:{${dataExpression.join(",")}}){
                  ${cols}
            }`
      }

      return exp

}

function updateRelationExpression(relationValues:string[],
                                  relationships: RelationType[],
                                  entity: EntityType,
                                  accessAttributeName: string,
                                  identityExpByEntity: string,
                                  expPrefix:string) {
      let relEntity = matchRelationshipEntity(relationships, entity, accessAttributeName)
      let relationship:RelationType = matchRelationship(entity, relEntity, relationships)
      let relationshipAccessAttributeName = undefined
      if (relationship.attribute_name1 === accessAttributeName) {
            relationshipAccessAttributeName = relationship.attribute_name2
      }

      if (relationship.attribute_name2 === accessAttributeName){
            relationshipAccessAttributeName = relationship.attribute_name1
      }

      let relName = relationship.name
      // 存在修改前的关系记录 （也就是访问属性关联的旧记录）
      let relExps: string[] = []
      for (let i = 0; i < relationValues.length; i++) {
            // 取出关系上原始主键
            let relationValue = relationValues[i]
            // 生成关系上原始主键表达式
            let identityExpByRelEntity = generationIdentityExpression(relEntity, JSON.parse(relationValue))
            // 生成删除 / 更新 关系的表达式
            // deleterel_et01__child_0: deleterel_et01__child(et01:{id:""}, child:{id:""})
            // createrel_et01__child_0: createrel_et01__child(et01:{id:""}, child:{id:""})
            //identityExpByEntity - identityExpByRelEntity
            let exp = `${expPrefix}${relName}_${i}: ${expPrefix}${relName}(${accessAttributeName}:{${identityExpByRelEntity}}, ${relationshipAccessAttributeName}:{${identityExpByEntity}}) \n`
            relExps.push(exp)
      }
      return relExps
}

function deleteRelationAttributeExpression(identityExpByEntity:string,
                                           entity: EntityType,
                                           relationships: RelationType[],
                                           formItem: FormItem[],
                                           submitData: { [index: string]: any }) {

      /**
       * # 删除关系
                   deleterel_et01__child_0: deleterel_et01__child(et01:{id:""}, child:{id:""})
                   deleterel_et01__child_1: deleterel_et01__child(et01:{id:""}, child:{id:""})
                   deleterel_et01__child_2: deleterel_et01__child(et01:{id:""}, child:{id:""})
       */

      let formItems = formItem.map(item => item)
      // 找到访问属性表单项
      formItems = formItems.filter(i => i.type === "relation")
      // 根据实际属性，找到提交的访问属性表单项
      formItems = formItems.filter(item => submitData.hasOwnProperty(item.name))
      let relExpMap = new Map()
      for (let formItem of formItems) {
            let exitRelRecord = formItem.fromFormItem?.child?.values.length > 0
            if (exitRelRecord) {
                  let relationValues = formItem.fromFormItem?.child?.values.map(v => v.value)
                  let relExps= updateRelationExpression(relationValues, relationships, entity, formItem.name, identityExpByEntity, "delete");
                  // {"访问属性": [表达式，...]}
                  relExpMap.set(formItem.name, relExps)
            }

      }

      return relExpMap

}

function createRelationAttributeExpression(identityExpByEntity:string,
                                           entity: EntityType,
                                           relationships: RelationType[],
                                           formItem: FormItem[],
                                           submitData: { [index: string]: any }) {
      /**
       * # 创建新关系
             createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})
             createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})
             createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})
       */

      let formItems = formItem.map(item => item)
      // 找到访问属性表单项
      formItems = formItems.filter(i => i.type === "relation")
      // 根据实际属性，找到提交的访问属性表单项
      formItems = formItems.filter(item => submitData.hasOwnProperty(item.name))
      let relExpMap = new Map()
      for (let formItem of formItems) {
            let relationValues = submitData[formItem.name];
            let relExps= updateRelationExpression(relationValues, relationships, entity, formItem.name, identityExpByEntity,"create");
            // {"访问属性": [表达式，...]}
            relExpMap.set(formItem.name, relExps)
      }
      return relExpMap
}



export function generationUpdateDataExpression(identityExpByEntity:string,
                                               entity: EntityType,
                                               relationships: RelationType[],
                                               formItem: FormItem[],
                                               submitData: { [index: string]: any }):string {

      /**
       *
       *
       mutation m {

                    # 更新普通属性
                    updateet01(identity:{id:""}, data:{attr5:1}) {
                      id
                    }

                    # 删除关系
                    deleterel_et01__child_0: deleterel_et01__child(et01:{id:""}, child:{id:""})
                    deleterel_et01__child_1: deleterel_et01__child(et01:{id:""}, child:{id:""})
                    deleterel_et01__child_2: deleterel_et01__child(et01:{id:""}, child:{id:""})

                    # 创建新关系
                    createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})
                    createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})
                    createrel_et01__child_0:createrel_et01__child(et01:{id:""}, child:{id:""})

               }
       *
       */

      let dataExpression: string[] = []

      // 过滤掉主键信息
      for (let pkAttr of entity.identity_type?.attributes) {
            formItem = formItem.filter(item => item.name !== pkAttr.name)
      }

      // 生成常规属性更新表达式
      let updateExp = updateBaseAttributeExpression(entity, formItem, submitData, identityExpByEntity)

      // 生成删除关系表达式
      let deleteExpMap = deleteRelationAttributeExpression(identityExpByEntity, entity, relationships, formItem, submitData)

      // 生成创建关系表达式
      let createExpMap = createRelationAttributeExpression(identityExpByEntity, entity, relationships, formItem, submitData)

      console.log("updateExp =>", updateExp)
      console.log("deleteExp =>", deleteExpMap)
      console.log("createExpMap =>", createExpMap)
      let deleteExp = []
      for (let vs:string[] of deleteExpMap.values()) {
            deleteExp.push(...vs)
      }

      let createExp = []
      for (let vs:string[] of createExpMap.values()) {
            createExp.push(...vs)
      }
      return [updateExp, deleteExp, createExp]
}


const parserRelationshipDataToExp = (attributeName:string, relationValue:string[],entity: EntityType, relationships: RelationType[]):IObject[] => {
      /**
       * 提交之后，解析关系上的主键记录，并转换为QL的表达式语句
       * 如:  ['{"id":1}']  =>  [{id:1}]
       */

      // 访问属性映射数据结构: 对象：['{"id":1}'] 列表：['{"id":1}', '{"id":2}']
      const itemValues: string[] = [] // [{id:"xxx"}]
      for (let v of relationValue) {
            const rvo = JSON.parse(v) // string to object
            const dimMappingEntity = GenerationDimensionMappingEntity(entity, relationships);
            const mappingEntity = dimMappingEntity.get(attributeName)
            const attrs = GenerationEntityPrimariesAttrs(mappingEntity)
            let colValues: string[] = []
            for (let attr of attrs) {
                  let tv: string = formatValueByType(attr.type, rvo[attr.name])
                  colValues.push(`${attr.name}: ${tv}`)
            }
            if (colValues.length > 0) {
                  itemValues.push(`{${colValues.join(", ")}}`)
            }

      }

      return itemValues

}
export function generationCreateDataExpression(entity: EntityType, relationships: RelationType[],
                                               formItems: FormItem[], submitData: { [index: string]: any }): string {
      let formItem = formItems.filter(item => item.type !=="files")
      let createDataExpression: string[] = []
      for (let item of formItem) {
            //'AUTO_INC' || attr.default_value?.type === 'RANDOM'
            let value = submitData[item.name]
            if (!value) {
                  continue
            }
            if (item.type === 'relation') {
                  // 访问属性映射数据结构：['{"id":1}']  转换为QL的执行语句 =>  [{id:1}]
                  const itemValues = parserRelationshipDataToExp(item.name, value, entity, relationships)
                  if (itemValues.length > 0) {
                        const fd: string = formattedDisplay(item.name, entity, relationships)
                        if (fd === '列表') {
                              // 列表：areas:[{id:"xxx"}, {id:"yyy"}]
                              createDataExpression.push(`${item.name}: [${itemValues.join(",")}]`)
                        }
                        if (fd === '对象') {
                              // 单个对象: line:{id:"xxx"}
                              createDataExpression.push(`${item.name}: ${itemValues[0]}`)
                        }
                  }

            }  else {
                  // name:"x"
                  let tv: string = formatValueByType(item.type, value)
                  createDataExpression.push(`${item.name}: ${tv}`)
            }

      }

      if (createDataExpression.length === 0) {
            return undefined
      }

      return createDataExpression.join(", ")
}

export function generationCreateFileObjectAttributesExpression(entity: EntityType, relationships: RelationType[],
                                                     formItems: FormItem[], submitData: { [index: string]: any }): [] {

      let createDataExpression: IObject[] = []
      let formItem = formItems.filter(item => item.type !=="files" && item.type !== "relation")
      for (let item of formItem) {
            let value:string = submitData[item.name]
            if (!value) {
                  continue
            }
            let tv: string = formatValueByTypeWithFileObject(item.type, value)
            createDataExpression.push({attribute_name: item.name, attribute_value: tv})
      }

      return createDataExpression

}

export function generationCreateFileObjectRelationshipExpression(entity: EntityType, relationships: RelationType[],
                                                     formItems: FormItem[], submitData: { [index: string]: any }): [] {

      let createDataExpression: IObject[] = []
      let formItem = formItems.filter(item => item.type ==="relation")
      for (let item of formItem) {
            let value:string[] = submitData[item.name]
            if (!value) {
                  continue
            }
                  let vs:IObject[] = []
                  value.map(v => {
                        vs.push(JSON.parse(v))
                  })
                  createDataExpression.push({
                        entity_name: item.name,
                        entity_id: vs
                  })
      }
      return createDataExpression

}

export function generationSelectedRelationRecord(metaEntity:EntityType, key:string, record:IObject):SelectedItem {
      let title = []
      let content = []
      for (let pkAttribute of metaEntity.identity_type.attributes) {
            title.push(pkAttribute.name)
            content.push(JSON.stringify({[pkAttribute.name]: record[pkAttribute.name]}))
      }
      // items.push({key: key, title: title.join(','), content: content.join(","), row: record})
      return {key: key, title: title.join(','), content: content.join(","), row:record}

}