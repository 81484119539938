import fetch from "isomorphic-unfetch";
import qs from "query-string";
import * as R from "ramda";
import {Modal, notification} from "antd";
import { firstUpperCase } from "./utils";
import { ss } from "../k2assets-auth";
import env from 'day4-utils/es/env';

export interface ResponseData<T> {
  code: number;
  message: string;
  info: string;
  page_info?: {
    pages: number;
    page_size: number;
    page_num: number;
    size: number;
    total: number;
  };
  data: T;
}

export interface RequestOptions {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: {
    [key: string]: any;
  };
  /** url 参数 */
  params?: {
    [key: string]: any;
  };
  /** body 参数 */
  data?: {
    [key: string]: any;
  };
  /** 出错时，是否弹出错误气泡（错误仍然会抛出） */
  silent?: boolean;
}

async function request<T>(
  options: RequestOptions,
  blob = false
): Promise<ResponseData<T> | undefined> {
  const {
    url,
    params,
    data,
    headers,
    method = "GET",
    silent = false
  } = options;
  const urlWithParams = `${url}${params ? `?${qs.stringify(params)}` : ""}`;

  // fixme new way to authenticate.
  const token = await localStorage.getItem("ssoToken");
  let tokenType = await localStorage.getItem("ssoTokenType");
  const sid = await localStorage.getItem("ssoSid");

  if (tokenType != null && tokenType != "") {
    tokenType = firstUpperCase(tokenType) + " "
  }

  const isFormData = data instanceof FormData;

  // fixme bad type
  let reqOptions:any = {
    method,
    body: isFormData ? data : (data && JSON.stringify(data)),
    // mode: 'cors',
    headers: {
      "Accept-Language": "zh",
      ...(token && token !== "null" ? {
        Authorization: "" + tokenType + token
      }: {
        Authorization: "Basic " + env.DAY4_APP_AUTH_TOKEN
      }),
       ...(sid && sid !== "null" ? {
        Sid: ""+sid
      }: {/* ... */}),
      ...headers
    }
  };

  if (method && ["POST", "PUT", "DELETE"].includes(method) && !isFormData) {
    if (!reqOptions.headers["Content-Type"]) {
      reqOptions = R.mergeDeepRight(reqOptions, {
        headers: {
          "Content-Type": "application/json",
        }
      });
    }
  }

  try {
    const result = await fetch(urlWithParams, reqOptions);

    if (result.status === 200 || result.status === 201) {
      // 成功或创建成功
      const resultJson = await blob ? result.blob() : result.json();
      return resultJson;
      // Modal.error({
      //   title: "错误",
      //   content: "您权限不足或登录时间超时，请重新登录",
      //   onOk: () => {
      //     localStorage.removeItem("ssoToken");
      //     if (window.process) {
      //       if (window.process.env.REACT_APP_BASE_URL) {
      //         window.location.href = `${window.process.env.REACT_APP_BASE_URL}/sso-login`;
      //       } else {
      //         window.location.href = "/sso-login";
      //       }
      //     } else {
      //       window.location.href = "/sso-login";
      //     }
      //   }
      // });
      // const resultJson = await result.json();
      // return resultJson;
    } else if (result.status === 401) {
      if (env.DAY4_APP_AUTH_ENABLED == "false") {
        Modal.error({
          title: "401",
          content: "您的访问被拒绝",
        });
        return 
      } 
      
      Modal.error({
        title: "错误",
        content: "您权限不足或登录时间超时，请重新登录",
        onOk: () => {
          ss.signOut();
          // fixme new way to authenticate
          // localStorage.removeItem("ssoToken");
          // if (window.process) {
          //   if (window.process.env.REACT_APP_BASE_URL) {
          //     window.location.href = `${window.process.env.REACT_APP_BASE_URL}/sso-login`;
          //   } else {
          //     window.location.href = "/sso-login";
          //   }
          // } else {
          //   window.location.href = "/sso-login";
          // }
        }
      });
      const resultJson = await result.json();
      return resultJson;
    } else {
      const resultJson = await result.json();
      // console.log(resultJson);
      // message.error(
      //   `${resultJson.message || ""} ${resultJson.info ||
      //   ""} ${resultJson.detail || ""}`
      // );
      throw resultJson;
    }
  } catch (error) {
    if (!silent) {
      if (error instanceof  Object
          && error.hasOwnProperty('errors')
          && error.errors instanceof Array
          && error.errors.length > 0) {
        notification.error({
          message: error.errors[0].path,
          description: error.errors[0].message
        });
      }else if (error instanceof  Object
          && error.hasOwnProperty('message')){
        notification.error({
          message: error.message,
          description: `${error.info || ''} ${error.detail || ''}`
        });

      }else{
        notification.error({
          message: error,
          description: ``
        });
      }

    }
    // console.log(error, error.status);
    throw error;
  }
}

export default request;
