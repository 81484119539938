import React from "react";
import {Modal} from "antd";
import DataForm from "./DataForm";
import {FormItem} from "./useUpsertEntityData";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";

interface Props {
  namespace:string
  entity:EntityType
  relationships:RelationType[]
  visible: boolean
  setVisible: (v: boolean) => void
  formSubmit: (v:{[index:string]:any}) => void
  curRecord?: any
  formItems?:FormItem[]
  afterClose: any
}

const UpsertModal: React.FC<Props> = props => {
  const {
    namespace,
    entity,
    relationships,
    visible,
    setVisible,
    formSubmit,
    curRecord,
    formItems,
    afterClose,
  } = props;


  return (
    <Modal
      visible={visible}
      title={curRecord ? "更新" : "新建"}
      footer={null}
      destroyOnClose
      maskClosable={false}
      afterClose={afterClose}
      onCancel={() => setVisible(false)}
    >
      <DataForm namespace={namespace}
                entity={entity}
                relationships={relationships}
                formItems={formItems}
                setVisible={(v) => setVisible(v)}
                formSubmit={ (v) => formSubmit(v) }
                curRecord={curRecord}/>

    </Modal>
  );
}


export default UpsertModal;
