import {Modal, Button, Form, message, Input, InputNumber, Radio, Divider, Checkbox} from 'antd'
import {Day4CustomFC} from "../../types";
import React, {useReducer, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {LocalSetterContext} from "day4-helper-contexts/es";
import {IState, ACTION_TYPE} from "./typings";
import {DataSourceCategory, DataSourceType} from "../../api/types/DataSourceTypes";
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import {addDS} from "./redux";
import useMyQuery from "../../api/useMyQuery";
import dsCSS from "./ds.module.scss";
import namespaceApi from '../../api/requests/namespaceReqs';

export interface ModalAddDataSourceProType {
  namespace: string,
  onOk: () => void
}

const ModalAddDataSource: Day4CustomFC<ModalAddDataSourceProType> = ({
  namespace,
  onOk,
}) => {

  const [form] = Form.useForm()
  const stateSetter = React.useContext(LocalSetterContext)
  const queryClient = useQueryClient();

  const [isShowDataSourceCategoryList, setIsShowDataSourceCategoryList] = useState(true)
  const [isShowCreateDataSourceForm, setIsShowCreateDataSourceForm] = useState(false)
  const [selectedDataSourceCategory, setSelectedDataSourceCategory] = useState<DataSourceCategory>()
  const [isShowCancelBtn, setIsShowCancelBtn] = useState(true)
  const [isShowCompleteBtn, setIsShowCompleteBtn] = useState(false)
  const [isShowPrevBtn, setIsShowPrevBtn] = useState(false)

  const [type, setType] = useState("")
  const [host, setHost] = useState("")
  const [port, setPort] = useState("")
  const [schema, setSchema] = useState("")

  const [credentailType, setCredentailType] = useState("user_pass")

  const [schemaWriteable, setSchemaWriteable] = useState(true)
  const [dataWriteable, setDataWriteable] = useState(true)

  const onSelectedDataSourceCategory = (c: DataSourceCategory) => {
    if (!c.supported) {
     message.info("未开通...")
     return
    }
    // 如果上一次选中的data source type 不等于当前选中的, 重新刷新下表单
    if (selectedDataSourceCategory?.name !== c.name) {
      form.resetFields();
      setType("")
      setHost("")
      setPort("")
      setSchema("")
      setSchemaWriteable(true)
      setDataWriteable(true)
    }

    setType(c.value)
    setSelectedDataSourceCategory(c)
    setIsShowDataSourceCategoryList(false)
    setIsShowCreateDataSourceForm(true)

    setIsShowCancelBtn(false)
    setIsShowCompleteBtn(true)
    setIsShowPrevBtn(true)
  }

  const initState:IState = {
    modalVisible: false,
  }
  const [databaseState, dispatch] = useReducer(addDS, initState);

  const mutationCreateDS = useMutation((ds: DataSourceType) => namespaceApi.bindDataSource(namespace, ds), {
    onSuccess: () => {

      queryClient.fetchQuery(['data_source_list'], ({ns = namespace}) => namespaceApi.listDataSource(ns))
        .then(function (data) {
          stateSetter((params:any)=>{
            params["dataSources"] = data
          })
        }).catch(function (err) {
        console.log("fetch namespace data source error: "+ err)
      })

    }
  });

  const handleShowModal = () => {
    dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: true})
  }

  const handleCancel = () => {
    form.resetFields();
    setSelectedDataSourceCategory(undefined)
    setIsShowDataSourceCategoryList(true)
    setIsShowCreateDataSourceForm(false)
    setIsShowCancelBtn(true)
    setIsShowCompleteBtn(false)
    setIsShowPrevBtn(false)
    setCredentailType("user_pass")
    setType("")
    setHost("")
    setPort("")
    setSchema("")
    setSchemaWriteable(true)
    setDataWriteable(true)
    dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: false})
  }
  const handleOk = async () => {
    try {
      // 从表单中取出字段
      const values = await form.validateFields();
      values['type'] = selectedDataSourceCategory?.value
  
      if (credentailType == "token") {
        if (!values["credentail"]) {
          message.error("token is empty")
          return
        }
      } else if (credentailType == "user_pass") {
        if (!values["username"] || !values["password"]) {
          message.error("username or password is empty")
          return
        }
      }

      values["schema_writeable"] = schemaWriteable
      values["data_writeable"] = dataWriteable

      console.log(values)
      const result = await  mutationCreateDS.mutateAsync(values)
      if (result?.data) {
        message.success("新增成功")
        form.resetFields()
        setSelectedDataSourceCategory(undefined)
        setIsShowDataSourceCategoryList(true)
        setIsShowCreateDataSourceForm(false)
        setIsShowCancelBtn(true)
        setIsShowCompleteBtn(false)
        setIsShowPrevBtn(false)
        setType("")
        setHost("")
        setPort("")
        setSchema("")
        setSchemaWriteable(true)
        setDataWriteable(true)

        setCredentailType("user_pass")
        dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: false})
      }
    } catch (e) {
      // no-op
    }
  }

  const handlePrev = () => {
    setIsShowCreateDataSourceForm(false)
    setIsShowDataSourceCategoryList(true)
    setIsShowPrevBtn(false)
    setIsShowCompleteBtn(false)
    setIsShowCancelBtn(true)
  }

  const footer = (
    <>
      <Button key="prev" type="dashed" onClick={handlePrev} hidden={!isShowPrevBtn}>上一步</Button>
      <Button key="ok" type="primary" onClick={handleOk} hidden={!isShowCompleteBtn}>完成</Button>
      <Button key="cancel" type="dashed" onClick={handleCancel} hidden={!isShowCancelBtn}>取消</Button>
    </>
  )

   const DataSourceCategory = () => {
    const { data: dataSourceCategoryListResponse } = useMyQuery(['datasource_categories'], dataSourceApi.categories);
    const dataSourceCategoryList = dataSourceCategoryListResponse?.data;
    // 按标签分下类
    const dataSourceCategoryMap = new Map<string, DataSourceCategory[]>()
    dataSourceCategoryList?.map(c => {
      let v = dataSourceCategoryMap.get(c.tag)
      if (v) {
        v.push(c)
      } else {
        v = new Array<DataSourceCategory>()
        v.push(c)
      }
      dataSourceCategoryMap.set(c.tag, v)
  })

    return (
      <div  hidden={!isShowDataSourceCategoryList}>
         {dataSourceCategoryMap && Array.from(dataSourceCategoryMap.entries()).map(([tag, list]) => (
        <div className={dsCSS.dataSourceTypeGroup}>
            <div>

            <div className={dsCSS.dataSourceTypeGroupTab}>
              <div className={dsCSS.dataSourceTypeGroupTabName}>{tag}</div>
            </div>
            <span style={{top: "-1px", position:"absolute", color: "red", left: "90px"}}/>

            {list && list.map(c => (
            <div className={c.supported ? dsCSS.dataSourceTypeItem: dsCSS.dataSourceTypeItemNotSupport} onClick={()=>onSelectedDataSourceCategory(c)}>
              <img src={c.icon} className={dsCSS.dataSourceTypeItemImg}/>
              <span className={dsCSS.dataSourceTypeItemName}>{c.name}</span>
            </div>))}
            </div>
          </div>

          ))}
      </div>
    )
  }

  return (
    <>
      <Button
        type={"primary"}
        onClick={handleShowModal}>新增数据源</Button>

      <Modal visible={databaseState.modalVisible}
             title={'新增数据源'}
             onCancel={handleCancel}
             maskClosable={false}
             destroyOnClose={true}
             footer={footer}
             width='41%'
             bodyStyle={{overflowY: 'auto', maxHeight:'670px'}}
      >
        {DataSourceCategory()}
      
        <Form
          name='CreateDatasource'
          form={form}
          labelCol={{span: 6}}
          preserve={false}
          hidden={!isShowCreateDataSourceForm}
        >

          <Form.Item
            label="连接信息"
            name="info"
          >
           <text>{type + "://" + host + ":" + port + "/" + schema}</text>
          </Form.Item>

          <Form.Item
            label="名称"
            name="name"
            rules={[
              {required: true, message: '名称不能为空'}
            ]}
          >
           <Input placeholder={"请输入数据源名称"} />
          </Form.Item>
          
          <Form.Item  label="主机" required>
          <Form.Item
             style={{ display: 'inline-flex'}}
             rules={[{required:true, message: "主机不能为空"}]}
             name="host"
          >
            <Input placeholder={"请输入主机"} onChange={(v) => {
               setHost(v.target.value)
            }}/>
          </Form.Item>
         
          <Form.Item 
             style={{ display: 'inline-flex', marginLeft: '17px'}}
              name="port"
              label= "端口"
              rules={[
                {required: true, message: '端口不能为空'}
              ]}>
            <InputNumber size={"middle"}  placeholder={"端口号"} onChange={(v) => {
              setPort(v ? v.toString(): "")
            }}/>
          </Form.Item>

          </Form.Item>

          <Form.Item label="数据库/模式"
              name="schema"
              rules={[
                {required: true, message: '不能为空'}
              ]}>
          <Input placeholder={""} onChange={(v) => {
             setSchema(v.target.value)
          }}/>
          </Form.Item>

          <Form.Item label="schema是否可写">
          <Form.Item 
            style={{ display: 'inline-flex'}}
            name="schema_writeable"
          >
					<Checkbox defaultChecked={true} onChange={ (v) => {
              if (v.target.checked) {
              setSchemaWriteable(true)
              } else {
                setSchemaWriteable(false)
              }
          } }/>
          </Form.Item>
           <Form.Item label="数据是否可写"
            style={{ display: 'inline-flex', marginLeft: '17px'}}
            name="data_writeable"
           >
          <Checkbox defaultChecked={true} onChange={ (v) => {
              if (v.target.checked) {
              setDataWriteable(true)
              } else {
                setDataWriteable(false)
              }
          } } />
          </Form.Item>
          </Form.Item>

         <Divider/>

          <Form.Item
            label = "认证方式"
          >
          <Radio.Group defaultValue={credentailType} size="small" onChange={(v) => {
            setCredentailType(v.target.value)
            console.log(credentailType)
          }}>
              <Radio.Button value="user_pass">用户名与密码</Radio.Button>
              <Radio.Button value="token">令牌</Radio.Button>
          </Radio.Group>

          </Form.Item>
         
          <Form.Item label="令牌"
              name="credentail"
              rules={[
                {required: false, message: '令牌不能为空'}
              ]}
              hidden={credentailType !== "token"}
              >
              <Input placeholder={""}/>
          </Form.Item>
         
          <>
            <Form.Item label="用户名"
                      name="username"
                      rules={[
                        {required: false, message: '用户名不能为空'}
                      ]} hidden={credentailType !== "user_pass"}>
            <Input placeholder={""}/>
          </Form.Item>
            <Form.Item label="密码"
                name="password"
                rules={[
                  {required: false, message: '密码不能为空'}
                ]} hidden={credentailType != "user_pass"}>
            <Input.Password placeholder={""}/>
          </Form.Item>
          </>
          
          <Divider/>
          <Form.Item label="数据源描述" name="desc">
              <Input placeholder={""} />
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}

export default ModalAddDataSource
